import styles from "./checklist.module.css";
import { useTranslation } from "react-i18next";

// Importing the validation rules
import {
  UPPERCASE_REGEX,
  NUMBER_REGEX,
  LENGTH_REGEX,
  SPECIAL_CHARS_REGEX,
} from "./validationRules";

// Moved rules inside the CheckList component to use `t` properly
const CheckList = ({ value }) => {
  const { t } = useTranslation();

  // Rules depend on `t`, so they should be defined after initializing `t`
  const rules = [
    { label: t("One uppercase"), pattern: UPPERCASE_REGEX },
    { label: t("One number"), pattern: NUMBER_REGEX },
    { label: t("Min 8 characters"), pattern: LENGTH_REGEX },
    { label: t("One special character"), pattern: SPECIAL_CHARS_REGEX },
  ];

  return (
    <div className={styles.wrapper}>
      {rules.map((rule, index) => {
        const isPassed = value?.match(rule.pattern);
        const className = isPassed ? styles.passed : "";
        return (
          <p
            key={index}
            className={className}
            style={{ textAlign: "left", fontSize: "16px" }}
          >
            {rule.label}
          </p>
        );
      })}
    </div>
  );
};

export default CheckList;
