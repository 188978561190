import React, { useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import { setModalPrincipal } from "../../shared/slice/ModalLogin/ModalLoginSlice";
import { useSelector } from "react-redux";
import ModalPrincipal from "../Modals/ModalPrincipal";
import { store } from "../../shared";
import ModalLogin from "../Modals/ModalLogin";
import ModalSignup from "../Modals/ModalSignup";
import ModalEmailSend from "../Modals/ModalEmailSend";
import { disconnect } from "../../shared/slice/auth/AuthSlice";
import styled from "styled-components";
import PersonIcon from "@mui/icons-material/Person";
import Cart from "../Cart/Cart";

import { useLocation, useNavigate } from "react-router-dom";
import Notification from "../Notification/notification";
import SideNavBar from "./SideNavBar";
import ModelGuest from "../Modals/ModelGuest";
import LogoEatorder from "../../assets/images/eatorder-logo.png";
import { setScroll } from "../../shared/slice/scroll/ScrollSlice";
export default function MyNavBar() {
  // const principalModal = useSelector(
  //   (state) => state.modalLogin.modalPrincipal
  // );


  
  const isLoggedIn = useSelector((state) => state.authentification.isLoggedIn);
  const orders = useSelector((state) => state.order.order);
  const selectedPromos = useSelector((state) => state.promos.selectedPromos);
  
  
  const openmodal = () => {
    store.dispatch(setModalPrincipal({ modalPrincipal: true }));
  };
  const logout = () => {
    // window.location.reload();
    store.dispatch(disconnect());
    console.log("wessdisconnects");
    localStorage.clear();

  };

  const navigate = useNavigate();
  const selectedRestaurant = useSelector(
    (state) => state.restaurant.restaurantSelected._id
  );
  const RestaurantSlug = useSelector(
    (state) => state.restaurant.restaurantSelected.slug
  );
  const user = useSelector((state) => state.authentification.loggedInUser);

  const location = useLocation();
  const { delivery } = location.state || {};
  const [selectOption, setSelectOption] = useState(null);
  useEffect(() => {
    if (location.pathname.includes("history")) {
      setSelectOption({
        value: "History",
        label: "History",
        icon: "HistoryIcon",
      });
    } else if (location.pathname.includes("favorites")) {
      setSelectOption({
        value: "Favorite",
        label: "Favorite",
        icon: "StarBorderIcon",
      });
    } else {
      setSelectOption({
        value: user?.firstName,
        label: user?.firstName,
        icon: "ManageAccountsIcon",
      });
    }
  }, [user]);

  // const options = [
  //   {
  //     value: user?.firstName,
  //     label: user?.firstName,
  //     icon: "ManageAccountsIcon",
  //   },
  //   // { value: "Profile", label: "Profile", icon: "ManageAccountsIcon" },
  //   // { value: "Favorite", label: "Favorite", icon: "StarBorderIcon" },
  //   { value: "History", label: "History", icon: "HistoryIcon" },
  //   { value: "Logout", label: "Logout", icon: "LogoutIcon" },
  // ];

  // const Option = (props) => (
  //   <components.Option {...props}>
  //     {props.data.icon === "HistoryIcon" && <HistoryIcon />}
  //     {/* {props.data.icon === "StarBorderIcon" && <StarBorderIcon />} */}
  //     {props.data.icon === "ManageAccountsIcon" && <ManageAccountsIcon />}
  //     {props.data.icon === "LogoutIcon" && <LogoutIcon />}
  //     {props.data.label}
  //   </components.Option>
  // );
  // const SingleValue = ({ children, ...props }) => (
  //   <components.SingleValue {...props}>
  //     {selectOption.icon === "HistoryIcon" && <HistoryIcon />}
  //     {selectOption.icon === "StarBorderIcon" && <StarBorderIcon />}
  //     {selectOption.icon === "ManageAccountsIcon" && <ManageAccountsIcon />}
  //     {selectOption.icon === "LogoutIcon" && <LogoutIcon />}
  //     {children}
  //   </components.SingleValue>
  // );
  // const handleChangeOption = (value) => {
  //   setSelectOption(value);
  //   if (value.value === "History") {
  //     navigate(`/ordershistory/${selectedRestaurant}`,{ state: { delivery: false } });
  //   } else if (value.value === "Favorite") {
  //     navigate(`/favoritesorders`);
  //   } else if (value.value === "Logout") {
  //     logout();
  //   }
  //   // else{
  //   //   navigate("/")
  //   // }
  // };
  // const loggedInUser = useSelector((state) => state.authentification.loggedInUser);

  // const [notifications, setNotifications] = useState([]);

  const Notifications = useSelector(
    (state) => state.ModalNotification.Notification
  );

  // useEffect(() => {
  //   const fetchNotifications = async () => {
  //     try {

  //       const userNotifications = await getNotification(loggedInUser._id);

  //       setNotifications(userNotifications);
  //     } catch (error) {

  //       console.error('Error fetching notifications:', error);
  //     }
  //   };
  //   fetchNotifications();
  // }, []);
  //function countReadNotifications(Notifications) {
  // let readCount = 0;

  // Notifications.forEach(notification => {
  // if (notification.read === false) {
  // readCount++;
  //}
  //});

  // return readCount;
  // }
  const [unreadCount, setUnreadCount] = useState(0);

  useEffect(() => {
    function countReadNotifications(Notifications) {
      return Notifications.reduce((readCount, notification) => {
        return notification.read === false ? readCount + 1 : readCount;
      }, 0);
    }

    const unread = countReadNotifications(Notifications);
    setUnreadCount(unread);
  }, [Notifications]);

  // const test = useSelector((state) => state.ModalNotification.Test);
  // const chekNotification = () => {
  //   if (test === false) {
  //     store.dispatch(setNotification({ Notification: [] }))
  //   }
  // }
  // useEffect(() => {
  //   chekNotification();
  // }, [test]);

  // const resto = useSelector((state) => state.restaurant.restaurantSelected);
  // const goBack = () => {
  //   navigate(-1);
  // };


  const goToStores = () => {

    if (location.pathname === `/ordershistory/${selectedRestaurant}` ) {
      store.dispatch(setScroll({ scroll: 1 }));
      if(delivery===undefined){navigate(`/${RestaurantSlug}`);}
      else {
  
        navigate(`/${RestaurantSlug}`, { state: { delivery: false } });
      }
     
    } else {
      if(delivery===undefined){   navigate("/");}
    }
    // localStorage.clear();
    // navigate(`/select-store/${selectedRestaurant}`);
  };

  return (
    <>
      <StyledNavbar
        className="bg-body-tertiary "
        style={
          {
            // background:`${color}`
          }
        }
      >
        <Container>
          <Navbar.Brand>
            <div style={{ display: "flex" }}>
              {/* {window.innerWidth < 1023  &&
                  <ArrowBackIcon style={{ color: "#eb1700", width: "32px", height: "32px" ,marginRight:"10px" }} onClick={goBack}/> } */}
              <Logo
                src={LogoEatorder}
                className="logo"
                onClick={goToStores}
              ></Logo>
            </div>
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            {!isLoggedIn && (
              <Nav.Item className="text-white">
               { delivery===undefined &&
                <button
                  className="btn"
                  onClick={() => openmodal()}
                  style={{ marginRight: "2px", padding: "0" }}
                >
                  <PersonIcon style={{ color: "#eb1700" }} />
                  <span className="login-button"></span>
                </button>}
                <ModalPrincipal />
                <ModalLogin />
                <ModelGuest />
                <ModalSignup />
                <ModalEmailSend />
              </Nav.Item>
            )}
            {isLoggedIn && (
              <Nav.Item>
                <CardBadgeContainer>
                  <Notification />
                  {unreadCount > 0 && (
                    <CardBadge>
                      <div>{unreadCount}</div>
                    </CardBadge>
                  )}
                </CardBadgeContainer>
              </Nav.Item>
            )}
            <Nav.Item>
              <CardBadgeContainer>
                <Cart />
              
                {(orders.length > 0 || selectedPromos.length > 0) && (
                  <CardBadge>
                    {/* <div>{orders.length + selectedPromos.length}</div> */}
                    <div>
                      {orders.reduce(
                        (total, order) => total + order.quantity,
                        0
                      ) +
                        selectedPromos.reduce(
                          (total, promo) => total + promo.promo.numberGroup,
                          0)
                          }  
                    </div>
                  </CardBadge>
                )}
              </CardBadgeContainer>
            </Nav.Item>
            <Nav.Item>
              <CardBadgeContainer>
                <SideNavBar />
              </CardBadgeContainer>
            </Nav.Item>
          </Navbar.Collapse>
        </Container>
      </StyledNavbar>
    </>
  );
}

export const StyledNavbar = styled(Navbar)`
  position: sticky !important;
  top: 0px !important;
  z-index: 1002;
`;
const Logo = styled.img`
  height: 25px;
  @media (max-width: 450px) {
    height: 18px;
  }
`;

const CardBadgeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #eb1700;
`;

const CardBadge = styled.h6`
  min-width: 20px;
  min-height: 20px;
  background-color: #000;
  border-radius: 50%;
  text-align: center;
  margin-left: -10px;
  color: #fff;
`;
