import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Modal from "react-modal";
import { BaseURI, ImageURI, store } from "../../shared";
import { useNavigate } from "react-router-dom";
import { setOrder } from "../../shared/slice/order/OrderSlice";
import { resetPromo } from "../../shared/slice/promos/PromosSlice";
import { useTranslation } from "react-i18next";
import { setStoresModal } from "../../shared/slice/ModalStores/ModalStores";
import ClearIcon from "@mui/icons-material/Clear";
import { setScroll } from "../../shared/slice/scroll/ScrollSlice";
import { getMode } from "../../shared/slice/restaurant/RestaurantService";
import { setMenu, setModeSelectedId, setRestaurantSelected } from "../../shared/slice/restaurant/RestaurantSlice";
import { disconnects } from "../../shared/slice/auth/AuthSlice";
import { setModelGuest } from "../../shared/slice/ModalLogin/ModalLoginSlice";
export const calculateDistance = (lat1, lon1, lat2, lon2) => {
  const R = 6371; // Radius of the Earth in km
  const dLat = (lat2 - lat1) * (Math.PI / 180);
  const dLon = (lon2 - lon1) * (Math.PI / 180);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1 * (Math.PI / 180)) *
    Math.cos(lat2 * (Math.PI / 180)) *
    Math.sin(dLon / 2) *
    Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c;
  return distance;
};
export default function ModalStores() {
  const loggedInGuest = useSelector((state) => state.authentification.loggedInGuest);

  const { t } = useTranslation();
  // const scroll = useSelector((state) => state.scroll.scroll);
  const navigate = useNavigate();
  const storesModal = useSelector((state) => state.modalStores.storesModal);
  const restaurants = useSelector((state) => state.restaurant.restaurant);
  const restaurantSelected = useSelector((state) => state.restaurant.restaurantSelected);
  const deliveryAddress = useSelector((state) => state.restaurant.location);
  // console.log(deliveryAddress);
  const [distances, setDistances] = useState({});
  const [filteredRestaurants, setFilteredRestaurants] = useState([]);
  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Radius of the Earth in km
    const dLat = (lat2 - lat1) * (Math.PI / 180);
    const dLon = (lon2 - lon1) * (Math.PI / 180);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) *
      Math.cos(lat2 * (Math.PI / 180)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
  };
  const calculateDistances = () => {
    if (deliveryAddress) {
      const distancesMap = {};
      const sortedStores = restaurants
        .map((store) => {
          const distance = calculateDistance(
            deliveryAddress.value.lat,
            deliveryAddress.value.lon,
            store.latitude,
            store.longitude
          ).toFixed(2);
          distancesMap[store._id] = distance;
          return {
            // ...store,
            distance: parseFloat(distance),
          };
        })
        .sort((a, b) => a.distance - b.distance);
      setDistances(distancesMap);
      setFilteredRestaurants(sortedStores);
    }
  };
  useEffect(() => {
    calculateDistances();
  }, []);
  useEffect(() => {
    if (storesModal) {
      store.dispatch(setScroll({ scroll: 1 }));
    }
  }, [storesModal]);
  const closeModal = () => {
    store.dispatch(setStoresModal({ storesModal: false }));
    store.dispatch(setScroll({ scroll: 0}));
  };
  const getIntoStore = (storeId,storeSlug) => {
    if (storeId === restaurantSelected?._id) {
      closeModal();
    } else {
      if(loggedInGuest && loggedInGuest!==false){
        console.log("wessdisconnects");
        store.dispatch(disconnects());
    
        store.dispatch(setModelGuest({ modelGuest: false }));
       }
       store.dispatch(setRestaurantSelected({ }));
       store.dispatch(setMenu({}));

      store.dispatch(setModeSelectedId({ modeSelectedId: "" }));

    //  const nameStorewithId = NameStore.replace(/\s+/g, '-')+storeId.slice(-4);
       navigate(`/${storeSlug}`);
      store.dispatch(setScroll({ scroll: 1}));
      store.dispatch(setOrder({ order: [] }));
      store.dispatch(resetPromo());

      closeModal();
      window.location.reload();
      //Zhml
      // Object.keys(localStorage).forEach((key) => {
      //   if (key.startsWith('QuantityInCart_')) {
      //     localStorage.removeItem(key);
      //   }
      // });
    }
  };
  const isOpenNow = (storeRes) => {
    const { openingdate } = storeRes;
    const currentTime = new Date();
    const currentDay = currentTime.toLocaleString('en-US', { weekday: 'long' });
    const currentTimeString = currentTime.toLocaleTimeString('en-US', { hour12: false });
    return openingdate.some(({ shifts, jour }) => {
      const { start, end } = shifts;
      const isOpen = jour[currentDay]?.isOpen;
      if (!isOpen) return false;
      if (end < start) {
        const adjustedCurrentTime = new Date(currentTime);
        adjustedCurrentTime.setDate(adjustedCurrentTime.getDate() - 1);
        const adjustedCurrentTimeString = adjustedCurrentTime.toLocaleTimeString('en-US', { hour12: false });
        return currentTimeString >= start || currentTimeString <= end || adjustedCurrentTimeString >= start;
      } else {
        return currentTimeString >= start && currentTimeString <= end;
      }
    });
  };
  const truncateAddress = (address) => {
    if (address.length > 35) {
      const lastSpaceIndex = address.lastIndexOf(' ', 40);
      return address.substring(0, lastSpaceIndex);
    } else {
      return address;
    }
  };
  const [mode, setMode] = useState({});
  // Get the modeSelectedId from the Redux store
  const modeSelectedId = useSelector((state) => state.restaurant.modeSelectedId);
  useEffect(() => {
    
    const fetchMode = async () => {
      try {
      
        if(modeSelectedId){
        const modeData = await getMode(modeSelectedId);
        
    
        setMode(modeData);
      }
      } catch (error) {
        
        console.error("Error fetching mode: ", error);
      }
    };
    if (modeSelectedId !== null && modeSelectedId !== undefined) {
      fetchMode();
    }
  }, [modeSelectedId]); // Re-run the effect when modeSelectedId changes
  return (
    <StyledModalMode
      isOpen={storesModal}
      onRequestClose={() => { closeModal() }}
    >
      <h3 style={{ zIndex: "1000" }}>{t('Select Store')}</h3>
      <StoresContainer>
        {restaurants.map((store) => {
          const isDelivery = mode?.mode?.name === "Delivery";
          const isWithinDistance = distances[store._id] !== undefined && distances[store._id] <= 60;
          // Conditional rendering based on the delivery mode and distance
          if (isDelivery && !isWithinDistance) return null;
          return (
            <StoreCard key={store._id} onClick={() => getIntoStore(store._id,store.slug)}>
              <StoreLogo src={`${ImageURI}/combined-uploads/${store.logo}`} alt="Store Logo" />
              <StoreDetails>
                <StoreStatus>
                  <h5>{store.name}</h5>
                </StoreStatus>
                <Adressp>
                  {truncateAddress(store.address)}
                </Adressp>
                {distances[store._id] > 0 && (
                  <DistanceLabel>
                    {`${(distances[store._id] | 0)} min`} {t('away')}
                  </DistanceLabel>
                )}
              </StoreDetails>
              <ActiveStatus><h6>{isOpenNow(store) ? t('Open 🟢') : t('Closed 🔴')}</h6></ActiveStatus>
            </StoreCard>
          );
        })}
      </StoresContainer>
      <ButtonClose
        onClick={() => { closeModal() }}
      >
        <ClearIcon style={{ fontSize: "27px" }} />
      </ButtonClose>
    </StyledModalMode>
  );
}
function ReactModalAdapter({ className, modalClassName, ...props }) {
  return (
    <Modal className={modalClassName} portalClassName={className} {...props} />
  );
}
const StyledModalMode = styled(ReactModalAdapter).attrs({
  modalClassName: "ModalModes",
  overlayClassName: "OverlayModes",
})`
  .ModalModes {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    // padding: 20px;
    border-radius: 15px;
    // height: 250px;
    // width: 400px;
    text-align: center;
    outline: none;
    height : 80%;
  }
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  @media (max-width: 768px) {
    .ModalModes {
      width: 95%;
      min-width: 90%;
      height:90%;
    }
  }
  .OverlayModes {
    background: rgba(0, 0, 0, 0.2);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9998;
  }
`;
const StoresContainer = styled.div`
  display: flex;
  flex-direction:column;
  height :94%;
  overflow-y:auto;
  padding: 20px;
  align-items: center;
  @media (max-width: 768px) {
    display:block;
    height:90%;
    overflow-y:auto;
    overflow-x:hidden;
  }
`;
const StoreCard = styled.div`
  width: 95%;
  height: 120px;
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  @media (max-width: 1024px) {
    width: 95%;
    height: 150px;
  }
  @media (max-width: 768px) {
    width: 95%;
    height: 150px;
  }
`;
const StoreLogo = styled.img`
  width: 80px;
  height: 80px;
  object-fit: contain;
  border-radius: 10px;
  margin-right: 1px;
`;
const StoreDetails = styled.div`
  flex-grow: 1;
`;
const StoreStatus = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
`;
const Adressp = styled.div`
  margin-bottom: 5px;
`;
const ActiveStatus = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
`;
const AdressInp = styled.div`
  width: 30%;
  padding: 20px 0;
  margin-left:35%;
  @media (max-width: 768px) {
    width:80%;
    margin-left:10%;
  }
`;
const DistanceLabel = styled.p`
  position: relative;
  color: #777;
  font-size: 1rem!important;
  @media (max-width: 768px) {
    font-size: 0.8rem!important;
  }
`;
const ButtonClose = styled.button`
  position: absolute;
  top: 5px;
  right: 10px;
  // padding-right: 10px;
  cursor: pointer;
  background-color: white;
  margin-top: 2px;
`;










