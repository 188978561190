import Modal from "react-modal";
import React, { useEffect, useState, useRef } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  deleteOrderNotification,
  setOrder,
} from "../../shared/slice/order/OrderSlice";
import { resetPromo } from "../../shared/slice/promos/PromosSlice";
import { BaseURI, store } from "../../shared";
import ClearIcon from "@mui/icons-material/Clear";

import {
  createOrder,
  createQuote,
  getCoupon,
  getUberToken,
} from "../../shared/slice/order/OrderService";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import { getMode } from "../../shared/slice/restaurant/RestaurantService";
import { useTranslation } from "react-i18next";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import Lottie from "lottie-react";
import rejectData from "../../assets/animations/reject.json";
import validData from "../../assets/animations/valid.json";
import waitData from "../../assets/animations/wait.json";
import ready from "../../assets/animations/ready.json";
import getSymbolFromCurrency from "currency-symbol-map";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Accordion from "react-bootstrap/Accordion";
import * as locales from "date-fns/locale";
import { setScroll } from "../../shared/slice/scroll/ScrollSlice";
import QrCode from "react-qr-code";
import Dialog from "@mui/material/Dialog";
import {
  disconnects,
  setmodalCheckout,
} from "../../shared/slice/auth/AuthSlice";
import {
  setModalPrincipal,
  setModelGuest,
} from "../../shared/slice/ModalLogin/ModalLoginSlice";
import {
  setAdresses,
  setDeliveryAdress,
  setDeliveryInformation,
  setLocation,
  setOutOfRange,
  setSelectedAdresse,
} from "../../shared/slice/restaurant/RestaurantSlice";
import {
  DeleteDeliveryAddress,
  GetAdressDeliveryByUserId,
} from "../../shared/slice/DeliveryAdresse/DeliveryAdresseService";
import { ToastContainer, toast } from "react-toastify";
import NewAddresse from "./NewAddresse";
import { useLocation } from "react-router-dom";
import { io } from "socket.io-client";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import Swal from "sweetalert2";
import "./checkout.css";

const userLanguage = navigator.language || navigator.userLanguage;
const languageCode = userLanguage.split("-")[0];
const locale = locales[languageCode];

function Checkout({
  modalCheckout,
  setModalCheckout,
  sumTotalInPromo,
  totalPrice,
  totalPriceHt,
}) {
  const [FinaltotalPrice, setFinaltotalPrice] = useState(totalPrice);
  const [FinaltotalPriceHt, settotalPriceHt] = useState(totalPriceHt);
  const [totalWorkOnce, settotalWorkOnce] = useState(true);
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();

  const [loadingAddress, setLoadingAddress] = useState(true);
  const [error, setError] = useState(null);
  const [errorMethodpaiement, setMethodpaiement] = useState(null);
  const [errorTimeorder, seterrorTimeorder] = useState(null);

  const [selectedDate, setSelectedDate] = useState(null);
  const [availableTimeOptions, setAvailableTimeOptions] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  const [selectedOrderTime, setSelectedOrderTime] = useState(null);
  const [notes, setNotes] = useState("");
  const [deliveryNotes, setDeliveryNotes] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [newAdresse, setNewAdresse] = useState(false);
  const storesConfirmationRef = useRef(null);
  const [confirmationClearCart, setConfirmationClearCart] = useState(false);
  const [OrderMin, setOrderMin] = useState(0);
  const [NameMode, setNameMode] = useState("0");
  const [customPhoneNumber, setCustomPhoneNumber] = useState("");
  const [processingP, setprocessingP] = useState(false);
  const deliveryInformation = useSelector(
    (state) => state.restaurant.deliveryInformation
  );
  const location = useLocation();

  const { delivery, vendeur, vendeurId } = location.state || {};

  const outOfRange = useSelector((state) => state.restaurant.outOfRange);

  const [taxesHt, setTaxesHt] = useState({
    Ht: 0,
    taxes: {},
  });
  const adresses = useSelector((state) => state.restaurant.adresses);
  const selectedAdresse = useSelector(
    (state) => state.restaurant.selectedAdresse
  );

  const [isLivraisonMode, setIsLivraisonMode] = useState(false);
  const isKioskLikely = useSelector((state) => state.modalLogin.IsKioskLikely);
  const orders = useSelector((state) => state.order.order);
  const selectedPromos = useSelector((state) => state.promos.selectedPromos);

  const [coupon, setCoupon] = useState("");
  const [message, setMessage] = useState("");
  const [isApplied, setIsApplied] = useState(false);
  useEffect(() => {
    setFinaltotalPrice(totalPrice);
    settotalPriceHt(totalPriceHt);
    settotalWorkOnce(true);
    setCoupon("");
    setMessage("");
    setIsApplied(false);
    setdiscountCoupon(null);
  }, [totalPrice]);
  // console.log("selectedPromo", selectedPromos);
  // console.log("orders", orders);
  // useEffect(() => {
  //   for (let l = 0; l < selectedPromos.length; l++) {
  //     for (let k = 0; k < selectedPromos[l].products.length; k++) {
  //      if (selectedPromos[l].products[k].price !== selectedPromos[l].products[k].price_after_discount ){
  //       for (let i = 0; i < Object.keys(selectedPromos[l].products[k].taxes).length; i++) {
  //         if (Object.keys(selectedPromos[l].products[k].taxes) == selectedPromos[l].products[k].product.tax) {
  //           Object.keys(selectedPromos[l].products[k].taxes)[i] = ((Object.keys(selectedPromos[l].products[k].taxes)[i] -()
  //         }
  //       }
  //      }
  //     }

  //   }
  //   }, [selectedPromos])

  const loggedInUser = useSelector(
    (state) => state.authentification.loggedInUser
  );

  const loggedInGuest = useSelector(
    (state) => state.authentification.loggedInGuest
  );

  // console.log("loggedInUser",loggedInUser);
  const openingDate = useSelector(
    (state) => state.restaurant.restaurantSelected.openingdate
  );
  const restaurantSelected = useSelector(
    (state) => state.restaurant.restaurantSelected
  );
  const modeSelectedId = useSelector(
    (state) => state.restaurant.modeSelectedId
  );

  const modes = useSelector((state) => state.restaurant.mode);
  const currency = useSelector((state) => state.restaurant.menu?.currency);
  const deliveryAdress = useSelector(
    (state) => state.restaurant.deliveryAdress
  );

  // console.log("deliveryAdress", deliveryAdress);
  const frais = useSelector((state) => state.restaurant.frais);
  const taux = useSelector((state) => state.restaurant.taux);
  const apply = useSelector((state) => state.restaurant.apply);

  useEffect(() => {
    if (loggedInUser && loggedInUser.phoneNumber) {
      setCustomPhoneNumber(loggedInUser.phoneNumber);
    }
    if (loggedInGuest && loggedInGuest.phoneNumber) {
      setCustomPhoneNumber(loggedInGuest.phoneNumber);
    }
  }, [loggedInUser, loggedInGuest]);

  useEffect(() => {
    if (modalCheckout) {
      setSelectedOrderTime(null);
      const fetchDeliveryAdresses = async () => {
        try {
          const res = await GetAdressDeliveryByUserId(loggedInUser._id);
          store.dispatch(setAdresses(res));
          // console.log("res", res);
          setLoadingAddress(false);
          // store.dispatch(setPromos({ promos: res.promos }));
        } catch (err) {
          console.log(err);
        }
      };
      fetchDeliveryAdresses();
    }
  }, [modalCheckout, deliveryAdress, modeSelectedId]);

  useEffect(() => {
    if (modalCheckout) {
      const NameMode = modes.find((m) => m.mode._id === modeSelectedId);
      setNameMode(NameMode.mode.name);
      setIsLivraisonMode(() => {
        return (
          modes.find((m) => m.mode._id === modeSelectedId)?.mode.name ===
          "Delivery"
        );
      });
      store.dispatch(setScroll({ scroll: 1 }));
      // Initialize variables to accumulate total priceHt and taxes
      let totalHt = 0;
      const totalTaxes = {};

      // Function to accumulate priceHt and taxes for each product
      const accumulateProductPriceAndTaxes = (product) => {
        totalHt += product.priceHt;
        for (const taxRate in product.taxes) {
          totalTaxes[taxRate] =
            (totalTaxes[taxRate] || 0) +
            product.taxes[taxRate] * product.quantity;
        }
      };

      // Accumulate from orders array
      if (orders !== undefined) {
        orders.forEach((product) => {
          accumulateProductPriceAndTaxes(product);
        });
      }
      // const handleCheckboxChange = () => {
      //   setIsChecked(!isNotChecked);
      // };
      // Accumulate from selectedPromos array
      if (selectedPromos !== undefined) {
        selectedPromos.forEach((promo) => {
          promo.products.forEach((product) => {
            accumulateProductPriceAndTaxes(product);
          });
        });
      }

      // Update state with accumulated values

      setTaxesHt({
        Ht: totalHt,
        taxes: totalTaxes,
      });

      setFinaltotalPrice(totalPrice);
      settotalPriceHt(totalPriceHt);
      settotalWorkOnce(true);
      setCoupon("");
      setMessage("");
      setIsApplied(false);
      setdiscountCoupon(null);
    }
  }, [modalCheckout, orders, selectedPromos, modeSelectedId]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleNoteChange = (event) => {
    setNotes(event.target.value);
  };
  const handleDeliveryNoteChange = (event) => {
    setDeliveryNotes(event.target.value);
  };
  const handleScheduleOrderChange = (event) => {
    setSelectedDate(null);
    setSelectedOrderTime(event.target.value);
  };

  const handlePaymentMethodChange = (event) => {
    setSelectedPaymentMethod(event.target.value);
  };

  const filterTime = (time) => {
    const oneHourFromNow = new Date();
    oneHourFromNow.setHours(oneHourFromNow.getHours() + 1);

    return availableTimeOptions.some(
      (option) =>
        option.getHours() === time.getHours() &&
        option.getMinutes() === time.getMinutes() &&
        time >= oneHourFromNow
    );
  };

  const clearDate = () => {
    // console.log("Order submitted for:", selectedDate);
    setAvailableTimeOptions([]);
    setSelectedDate(new Date());
  };
  const isOpenNow = (storeRes) => {
    const { openingdate } = storeRes;
    const currentTime = new Date();
    const currentDay = currentTime.toLocaleString("en-US", { weekday: "long" });
    const currentTimeString = currentTime.toLocaleTimeString("en-US", {
      hour12: false,
    });
    return openingdate.some(({ shifts, jour }) => {
      const { start, end } = shifts;
      const isOpen = jour[currentDay]?.isOpen;
      if (!isOpen) return false;
      if (end < start) {
        const adjustedCurrentTime = new Date(currentTime);
        adjustedCurrentTime.setDate(adjustedCurrentTime.getDate() - 1);
        const adjustedCurrentTimeString =
          adjustedCurrentTime.toLocaleTimeString("en-US", { hour12: false });
        return (
          currentTimeString >= start ||
          currentTimeString <= end ||
          adjustedCurrentTimeString >= start
        );
      } else {
        return currentTimeString >= start && currentTimeString <= end;
      }
    });
  };
  const [isNotChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isNotChecked);
  };
  useEffect(() => {
    if (selectedDate) {
      const selectedDay = selectedDate.toLocaleDateString("en-US", {
        weekday: "long",
      });
      // Filter shifts that are open on the selected day
      const shiftsForDay = openingDate.filter(
        (shift) => shift.jour[selectedDay]?.isOpen
      );
      // Generate time options based on all shifts for the selected day
      const timeOptions = [];
      shiftsForDay.forEach((shift) => {
        let currentTime = new Date(`2022-01-01T${shift.shifts.start}`);
        const endTime = new Date(`2022-01-01T${shift.shifts.end}`);

        while (currentTime < endTime) {
          timeOptions.push(new Date(currentTime));
          currentTime.setMinutes(currentTime.getMinutes() + 15);
        }
      });
      setAvailableTimeOptions(timeOptions);
    }
  }, [selectedDate]);
  const handlePayment = async () => {
    setError(null); // Reset error state
    setprocessingP(true); // Show processing indicator

    try {
      // Ensure Stripe.js and cardElement are available
      const cardElement = elements.getElement(CardElement);
      if (!stripe || !cardElement) {
        throw new Error("Stripe.js has not loaded. Please try again later.");
      }

      // Create a payment method
      const { error: stripeError, paymentMethod } =
        await stripe.createPaymentMethod({
          type: "card",
          card: cardElement,
        });

      if (stripeError) {
        throw new Error(stripeError.message); // Stripe validation error
      }

      // Proceed to transfer funds
      const response = await fetch(`${BaseURI}/socket/transfer-funds`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          amount: FinaltotalPrice,
          paymentMethodId: paymentMethod.id,
          connectedAccountId: restaurantSelected.stripeAccountId,
        }),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data?.error || "Failed to transfer funds.");
      }

      // Check payment intent status
      const { paymentIntent } = data;
      if (
        paymentIntent?.status === "requires_action" &&
        paymentIntent?.next_action
      ) {
        // Handle actions like 3D Secure authentication
        const { error: confirmError, paymentIntent: confirmedPaymentIntent } =
          await stripe.confirmCardPayment(paymentIntent.client_secret);

        if (confirmError) {
          setError(confirmError.message);
          return;
          //throw new Error(confirmError.message); // Authentication failed
        }

        if (confirmedPaymentIntent?.status !== "succeeded") {
          setError("Payment failed.");
          return;
          //  throw new Error("Payment failed after authentication.");
        }

        return confirmedPaymentIntent.id;
      } else if (paymentIntent?.status !== "succeeded") {
        setError("Payment failed.");
        return;
        // throw new Error("Payment failed.");
      }

      return paymentIntent.id;
    } catch (error) {
      console.error("Payment failed:", error.message);
      setError(error.message);
      return "false";
    } finally {
      setprocessingP(false); // Clear processing state
    }
  };

  //Save New Delivery Adresse
  // const handleSave = async () => {
  //   if (newType !== "") {
  //     setDeliveryInformation({
  //       ...deliveryInformation,
  //       type: newType,
  //     });
  //   }
  //   try {
  //     store.dispatch(
  //       setDeliveryAdress({
  //         deliveryAdress: location.label,
  //       })
  //     );

  //     const addAdresseresponse = await AddDeliveryAddress(
  //       loggedInUser._id,
  //       location.label,
  //       deliveryInformation.type
  //     );
  //     console.log(addAdresseresponse);
  //   } catch (error) {
  //     // Handling any errors that occur during the process
  //     console.error("Error adding address:", error);
  //   }
  // };

  //Create Order

  const handleDeleteOrder = () => {
    if (delivery !== undefined && delivery === false) {
      store.dispatch(disconnects());
      store.dispatch(setModelGuest({ modelGuest: false }));
      const newSocket = io(BaseURI);
      newSocket.disconnect(); // Ensure the socket is disconnected on unmount

      // Socket.off()
    }
    // setModalCheckout(false);

    setAvailableTimeOptions([]);
    setSelectedDate(new Date());
    store.dispatch(setOrder({ order: [] }));

    function clearQtyInCartForThisStore(prefix) {
      // Get all keys from localStorage
      const keys = Object.keys(localStorage);

      // Loop through keys and remove items that start with the specified prefix
      keys.forEach((key) => {
        if (key.startsWith(prefix)) {
          localStorage.removeItem(key);
        }
      });
    }

    clearQtyInCartForThisStore(
      `QuantityInCart_ForStoreWithId${restaurantSelected.slug}`
    );
    localStorage.removeItem(`OrderStoreWithID` + restaurantSelected.slug);

    // localStorage.clear();
    store.dispatch(resetPromo());
    // timer
    setStage(2);
    store.dispatch(setOutOfRange({ outOfRange: false }));
    store.dispatch(setSelectedAdresse([]));
    const productsToDelete = [];

    orders.forEach((order) => {
      productsToDelete.push(order.product.id);
    });

    productsToDelete.forEach((productId) => {
      const quantityKey = `QuantityInCart_ForStoreWithId${restaurantSelected.slug}_ForProductWith${productId}`;

      localStorage.removeItem(quantityKey);
    });
    // delay(orderResponse.date, orderResponse.order._id);
  };
  const handlesetStage = async () => {
    setCustomPhoneNumber("");
    setSelectedOrderTime(null);
    setSelectedPaymentMethod("");
    setIsChecked(false);
    setStage(1);
  };

  const validatePhoneNumber = (number) => {
    const parsedNumber = parsePhoneNumberFromString(number);
    return parsedNumber ? parsedNumber.isValid() : false;
  };
  const ShowError = (message) => {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: t(message),
      showCloseButton: true, // Show the close button (X)
      confirmButtonText: t("Close"), // Custom text for the confirmation button
      customClass: {
        popup: "swal2-popup-custom", // Custom class for the popup
        container: "swal2-container-custom", // Custom class for the container
        confirmButton: "swal2-confirm-button-custom", // Custom class for the confirm button
        closeButton: "swal2-close-button-custom", // Custom class for the close button
      },
    });
  };
  const handleOrder = async () => {
    setMethodpaiement(null);
    seterrorTimeorder(null);
    seterrorPhone(null);
    setprocessingP(true);
    try {
      if (selectedOrderTime === null) {
        ShowError("Select Time Order");
        seterrorTimeorder("Select Time Order");
        setprocessingP(false);
        return;
      }
      if (selectedOrderTime === "ScheduleOrder" && selectedDate === null) {
        seterrorTimeorder("Select Time Order");
        ShowError("Select Time Order");
        setprocessingP(false);
        return;
      }
      if (!selectedPaymentMethod) {
        console.error("No payment method selected. Order creation aborted.");
        setMethodpaiement(
          "No payment method selected. Order creation aborted."
        );
        ShowError("No payment method selected. Order creation aborted.");
        setprocessingP(false);
        return;
      }
      setMethodpaiement(null);
      if (selectedOrderTime === "") {
        seterrorTimeorder("Select Time Order");
        ShowError("Select Time Order");
        setprocessingP(false);
        return;
      }
      seterrorTimeorder(null);
      if (customPhoneNumber === "" || customPhoneNumber === undefined) {
        seterrorPhone("Enter your phone number");
        ShowError("Enter your phone number");
        setprocessingP(false);
        return;
      }
      // console.log("customPhoneNumber", customPhoneNumber);
      const valid = validatePhoneNumber(customPhoneNumber);
      if (valid === false) {
        seterrorPhone("Please correct the phone number.");
        ShowError("Please correct the phone number.");
        setprocessingP(false);
        return;
      }

      //  const numberphone="1111";
      //  const valid = isValidPhoneNumber(numberphone, 'FR');
      //  console.log("valid",valid)
      //  return;
      seterrorPhone(null);

      if (!isNotChecked) {
        // console.log("isNotChecked!!!", isNotChecked);
        if (storesConfirmationRef.current) {
          storesConfirmationRef.current.classList.add("red-border");
          setprocessingP(false); // Add red border class
          setTimeout(() => {
            storesConfirmationRef.current.classList.remove("red-border"); // Remove red border class after 10 seconds
          }, 1500); // 10000 milliseconds = 10 seconds
        } else {
          console.error('Element with class "stores-confirmation" not found.');
          setprocessingP(false);
        }
        return;
      } else {
        if (
          modes.filter((key) => key.mode._id === modeSelectedId)[0].mode
            .name === "Delivery"
        ) {
          const token = await getUberToken();
          if (!token) {
            return;
          }
          try {
            const data = {
              pickup_address: restaurantSelected.address,
              dropoff_address: deliveryAdress,
              external_store_id: restaurantSelected._id,
            };
            var createquote = await createQuote(data, token.accessToken);
          } catch (err) {
            if (err.response.status === 400) {
              // console.log(err, "errrrrrrrrrrrrr", err.response.data.message);
              setprocessingP(false); // Clear processing state

              toast.error(err.response.data.message);
              console.log(err.response.data.message);
              // setDeliveryError(err.response.data.message);
              return;
            }
          }
        }

        setIsButtonDisabled(true);

        const mode = await getMode(modeSelectedId);

        if ((loggedInUser || loggedInGuest) && isNotChecked === true) {
          if (
            (isOpenNow(restaurantSelected) && selectedOrderTime === "ASAP") ||
            (isOpenNow(restaurantSelected) &&
              selectedOrderTime === "ScheduleOrder" &&
              selectedDate !== null) ||
            (!isOpenNow(restaurantSelected) && selectedDate !== null)
          ) {
            // console.log(
            //   ",,,,,,,,,,,,",
            //   isOpenNow(restaurantSelected) ||
            //     (!isOpenNow(restaurantSelected) && selectedDate !== null)
            // );
            let orderSchema = [];
            let PromoSchema = [];

            let myOrder = {};
            for (let i = 0; i < orders.length; i++) {
              // console.log("tax",orders[i].taxes);
              orderSchema[i] = {
                description: orders[i].product.description,
                name: orders[i].product.name,
                image: orders[i].product.image,
                id: orders[i].product.id,
                item_price: parseFloat(orders[i].product.price).toFixed(2),
                price: parseFloat(orders[i].price).toFixed(2),
                priceHt: parseFloat(orders[i].priceHt).toFixed(2),
                quantity: orders[i].quantity,
                size: orders[i].product.size,
                options: orders[i].options,
                tax: orders[i].taxes,
                taxId: orders[i].taxesId,
                note: orders[i].note,
              };
            }

            let price_total = 0;
            for (const element of orders) {
              price_total += element.price;
            }
            for (let i = 0; i < selectedPromos.length; i++) {
              PromoSchema[i] = {};
              PromoSchema[i].promoId = selectedPromos[i].promo.promoId;
              PromoSchema[i].name = selectedPromos[i].promo.name;
              PromoSchema[i].items = [];
              for (let j = 0; j < selectedPromos[i].products.length; j++) {
                price_total +=
                  selectedPromos[i].products[j].price_after_discount;
                PromoSchema[i].items[j] = {
                  description:
                    selectedPromos[i].products[j].product.description,
                  image: selectedPromos[i].products[j].product.image,
                  name: selectedPromos[i].products[j].product.name,
                  id: selectedPromos[i].products[j].product.id,
                  price: parseFloat(
                    selectedPromos[i].products[j].price
                  ).toFixed(2),
                  priceHt: parseFloat(
                    selectedPromos[i].products[j].priceHt
                  ).toFixed(2),
                  item_price: parseFloat(
                    selectedPromos[i].products[j].product.price
                  ).toFixed(2),
                  price_after_discount: parseFloat(
                    selectedPromos[i].products[j].price_after_discount -
                      selectedPromos[i].products[j].options.reduce(
                        (total, option) => {
                          // Sum the 'price_opt' values from the 'options'CardElement array
                          const optionPriceSum = option.options.reduce(
                            (optionTotal, subOption) =>
                              optionTotal + subOption.price,
                            0
                          );

                          // Add the sum of 'price_opt' values from 'options' array and 'price_opt' value of the current 'option' object
                          return total + optionPriceSum + option.price_opt;
                        },
                        0
                      )
                  ).toFixed(2),
                  subtotal: parseFloat(
                    selectedPromos[i].products[j].price_after_discount
                  ).toFixed(2),
                  quantity: selectedPromos[i].products[j].quantity,
                  size: selectedPromos[i].products[j].product.size,
                  options: selectedPromos[i].products[j].options,
                  tax: selectedPromos[i].products[j].taxes,
                  note: selectedPromos[i].products[j]?.note,
                };
              }
            }
            myOrder.items = orderSchema;
            myOrder.promo = PromoSchema;
            let ff = 0;
            let ff2 = FinaltotalPriceHt;

            if (apply === "order") {
              ff = frais;
              ff2 = taxesHt.Ht;
            }
            myOrder.price_total = parseFloat(FinaltotalPrice + ff).toFixed(2);
            myOrder.priceHt_total = parseFloat(ff2).toFixed(2);
            myOrder.priceWithoutFee = parseFloat(
              FinaltotalPrice -
                (frais !== null ? frais : FinaltotalPrice * (taux / 100))
            ).toFixed(2);
            // Assign client information based on whether a user or guest is logged in
            if (loggedInUser) {
              myOrder.client_first_name = loggedInUser.firstName;
              myOrder.client_last_name = loggedInUser.lastName;
              myOrder.client_email = loggedInUser.email;
              myOrder.client_phone = customPhoneNumber;
              myOrder.userId = loggedInUser._id;
            } else if (loggedInGuest) {
              myOrder.client_first_name = loggedInGuest.firstName;
              myOrder.client_last_name = loggedInGuest.lastName;
              // myOrder.client_email = loggedInGuest.email;
              myOrder.client_phone = customPhoneNumber;
              myOrder.userId = loggedInGuest._id;
            }
            if (delivery !== undefined && delivery === false) {
              myOrder.vendeurID = vendeurId;
              myOrder.vendeur = vendeur;
            }

            myOrder.storeId = restaurantSelected._id;
            myOrder.paymentMethod = selectedPaymentMethod;
            myOrder.type = mode.mode.name;
            myOrder.modeId = mode.mode._id;
            myOrder.currency = currency;
            myOrder.restaurantAdress = restaurantSelected.address;

            if (deliveryAdress) {
              myOrder.deliveryAdress = deliveryAdress;
              myOrder.deliveryInformation = {
                ...deliveryInformation,
                order_time: selectedOrderTime,
                preparedDate: selectedDate,
                DeliveryNote: deliveryNotes,
              };
            }
            if (notes) {
              myOrder.notes = notes;
            }

            if (selectedDate instanceof Date) {
              const timeString = selectedDate.toTimeString().split(" ")[0];
              // console.log("timeString", selectedDate);
              const date = new Date(`2000-01-01T${timeString}`);

              if (!isNaN(date)) {
                date.setMinutes(date.getMinutes());
                const newTime = `${String(date.getHours()).padStart(
                  2,
                  "0"
                )}:${String(date.getMinutes()).padStart(2, "0")}:${String(
                  date.getSeconds()
                ).padStart(2, "0")}`;

                const dateComponents = selectedDate.toString().split(" ");
                dateComponents[4] = newTime;

                const newDateString = dateComponents.join(" ");
                myOrder.preparedAt = newDateString;
              }
              //else {
              //   console.error("Invalid date or pickup_duration");
              // }
            } else {
              myOrder.preparedAt = new Date();
              console.error("selectedDate is not a valid Date instance");
            }

            const MinOrder = mode.mode.MinOrder;
            setOrderMin(MinOrder);
            setNameMode(mode.mode.name);

            if (parseFloat(MinOrder) > parseFloat(myOrder.price_total)) {
              setprocessingP(false); // Clear processing state

              setConfirmationClearCart(true);

              return;
            }
            if (selectedPaymentMethod === "Credit/Debit Card") {
              const paymentSuccess = await handlePayment();

              if (paymentSuccess === undefined || paymentSuccess === "false") {
                console.error(
                  "Payment was not successful. Order creation aborted."
                );
                return;
              }
              // myOrder.paymentStatus = "Paid";
              myOrder.refundId = paymentSuccess;
              myOrder.paymentStatus = "paid";
            }

            const firebaseToken = localStorage.getItem("firebaseToken");
            if (firebaseToken !== undefined) {
              myOrder.firebaseToken = firebaseToken;
              const lng = localStorage.getItem("i18nextLng");
              const firstTwoChars = lng ? lng.substring(0, 2) : "";

              myOrder.language = firstTwoChars;
            } else {
              myOrder.language = "en";
              myOrder.firebaseToken = "None";
            }

            const orderResponse = await createOrder(myOrder);

            // if (delivery !== undefined && delivery === false) {
            //   store.dispatch(disconnects());
            //   store.dispatch(setModelGuest({ modelGuest: false }));
            //   const newSocket = io(BaseURI);
            //   newSocket.disconnect(); // Ensure the socket is disconnected on unmount

            //   // Socket.off()
            // }
            // // setModalCheckout(false);
            // setSelectedPaymentMethod("cash");
            // setAvailableTimeOptions([]);
            // setSelectedDate(new Date());
            // store.dispatch(setOrder({ order: [] }));

            // function clearQtyInCartForThisStore(prefix) {
            //   // Get all keys from localStorage
            //   const keys = Object.keys(localStorage);

            //   // Loop through keys and remove items that start with the specified prefix
            //   keys.forEach((key) => {
            //     if (key.startsWith(prefix)) {
            //       localStorage.removeItem(key);
            //     }
            //   });
            // }

            // clearQtyInCartForThisStore(
            //   `QuantityInCart_ForStoreWithId${restaurantSelected.slug}`
            // );
            // localStorage.removeItem(
            //   `OrderStoreWithID` + restaurantSelected.slug
            // );

            // // localStorage.clear();
            // store.dispatch(resetPromo());
            // // timer
            delay(orderResponse.date, orderResponse.order._id);
            setStage(2);

            setprocessingP(false);
            // store.dispatch(setOutOfRange({ outOfRange: false }));
            // store.dispatch(setSelectedAdresse([]));
          }
        }
        // Initialize array to store order IDs
        // const productsToDelete = [];

        // orders.forEach((order) => {
        //   productsToDelete.push(order.product.id);
        // });

        // productsToDelete.forEach((productId) => {
        //   const quantityKey = `QuantityInCart_ForStoreWithId${restaurantSelected.slug}_ForProductWith${productId}`;

        //   localStorage.removeItem(quantityKey);
        // });
      }

      // setTimeout(() => {
      //   setIsButtonDisabled(false);
      // }, 5000);
    } catch (error) {
      console.error("Error creating order:", error);
      setprocessingP(false);
    }
  };

  useEffect(() => {
    setTimeout(() => setIsButtonDisabled(false), 2000);
  }, [isButtonDisabled]);

  const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <input
      type="text"
      value={value}
      onClick={onClick}
      placeholder={t("Month D, YYYY HH:MM")}
      readOnly
      style={{
        cursor: "pointer",
        border: "1px solid grey",
        marginLeft: "10px",
        borderRadius: "5px 0px 0px 5px",
      }}
      ref={ref}
    />
  ));

  const [stage, setStage] = useState(1);
  const [timer, setTimer] = useState(null);
  const OrderNotification = useSelector(
    (state) => state.order.OrderNotification
  );

  const delay = (date, orderId) => {
    const intervalID = setInterval(() => {
      const currentDate = Date.now();
      const timeDifference = new Date(date.toString()) - currentDate;
      const minutes = Math.floor(timeDifference / (1000 * 60));
      const seconds = Math.floor((timeDifference / 1000) % 60);
      // console.log("test");
      if (timeDifference >= 0) {
        setTimer({
          intervalID: intervalID,
          orderId,
          timeRemaning: `${t("Minutes")}: ${minutes}, ${t(
            "Seconds"
          )}: ${seconds} ${t("left.")}`,
        });
      }
      // 11-07-2024 - STOP COUNTER
      // const order = OrderNotification.find(
      //   (notification) => notification.orderId === orderId
      // );
      // if (order?.status !== "pending") {
      //   clearInterval(intervalID);
      // }
      // 11-07-2024 - STOP COUNTER
    }, 1000);
  };
  const closeCheckoutModal = () => {
    store.dispatch(setScroll({ scroll: 0 }));
    store.dispatch(setmodalCheckout({}));
    setModalCheckout(false);
    // setSelectedPaymentMethod("cash");
    setSelectedOrderTime(null);
    setAvailableTimeOptions([]);
    setSelectedDate(new Date());
    setError(null);
    store.dispatch(setOutOfRange({ outOfRange: false }));
    store.dispatch(setSelectedAdresse([]));
    // timer
    setStage(1);
    setTimer(null);
    store.dispatch(deleteOrderNotification());
    setLoadingAddress(true);
    if (loggedInGuest) {
      if (isKioskLikely) {
        store.dispatch(disconnects());

        store.dispatch(setModelGuest({ modelGuest: false }));
      } else {
        store.dispatch(setModalPrincipal({ modalPrincipal: false }));
      }
    }
  };
  useEffect(() => {
    const orderexists = OrderNotification?.find(
      (notification) => notification?.orderId === timer?.orderId
    );
    if (orderexists && orderexists?.status !== "ready") {
      clearInterval(timer.intervalID);
    }
  }, [OrderNotification]);

  //Address delete
  const handleAddressDelete = async (userId, NameAddress, Type) => {
    try {
      const response = await DeleteDeliveryAddress(userId, NameAddress, Type);
      // console.log("Address deleted successfully:", response);
      const res = await GetAdressDeliveryByUserId(loggedInUser._id);
      store.dispatch(setAdresses(res));
      return response;
    } catch (error) {
      console.error("Error deleting address:", error);
      throw error;
    }
  };
  const modalCheckouts = useSelector(
    (state) => state.authentification.modalCheckout
  );

  useEffect(() => {
    const notification = OrderNotification.find(
      (notification) => notification?.orderId === timer?.orderId
    );

    if (
      notification?.status === "ready" ||
      notification?.status === "accepted"
    ) {
      handleDeleteOrder(); // Call your function here
    }
  }, [OrderNotification]);
  // const handleClose = () => {
  //   // setShow(false);
  //   setConfirmationClearCart(false);
  //   store.dispatch(setScroll({ scroll: 0 }));
  // };
  // const [customPhoneNumber, setCustomPhoneNumber] = React.useState(
  //   loggedInGuest?.phoneNumber || ""
  // );
  const [errorPhone, seterrorPhone] = useState(null);
  const [discountCoupon, setdiscountCoupon] = useState(null);
  const handlePhoneNumberChange = (value) => {
    setCustomPhoneNumber(value);
    seterrorPhone(null); // Clear errors on change
  };
  const handleApply = async () => {
    try {
      if (coupon.trim() === "") {
        setMessage("Invalid Coupon");
        setIsApplied(false);
        return;
      }
      if (totalWorkOnce === false) {
        setMessage("Coupon Valid Once !");
        setIsApplied(false);
        // setIsApplied(false);
        return;
      }
      const response = await getCoupon(restaurantSelected._id, coupon.trim());

      if (response.success === true) {
        const discount = response.data.discount;

        const FinaltotalPrice02 =
          FinaltotalPrice - (FinaltotalPrice * discount) / 100;
        setFinaltotalPrice(FinaltotalPrice02);
        const FinaltotalPrice023 =
          totalPriceHt - (totalPriceHt * discount) / 100;
        settotalPriceHt(FinaltotalPrice023);

        setdiscountCoupon(discount);
        setMessage(t(response.message));
        setIsApplied(true);
        settotalWorkOnce(false);
        const tx = {};

        // Loop through each tax key in taxesHt.taxes and calculate the tax amounts
        Object.keys(taxesHt.taxes).forEach((key) => {
          const tax = taxesHt.taxes[key];

          // Calculate tax after applying the discount
          const taxAmount = tax - (tax * discount) / 100;

          // Assign the calculated taxAmount to the tx object, using the key as the property name
          tx[key] = taxAmount;
        });

        // Log the final tx object and tax calculation for Ht after discount

        // Update the taxesHt state with the discounted Ht and calculated taxes
        setTaxesHt({
          Ht: taxesHt.Ht - (taxesHt.Ht * discount) / 100,
          taxes: tx, // Now tx is an object, not an array
        });
      } else {
        setMessage(t(response.message));
        setIsApplied(false);
      }
      // return response;
    } catch (error) {
      console.error("Error deleting address:", error);
      // throw error;
      setMessage("A connection issue occurred. Please try again later.");
    }
  };

  // const handleRemove = () => {
  //   setCoupon("");
  //   setMessage("");
  //   setIsApplied(false);
  // };

  return (
    (loggedInUser || loggedInGuest) && (
      <div>
        <Dialog
          open={confirmationClearCart}
          style={{ zIndex: 11111 }}
          maxWidth="md"
          //  fullWidth
        >
          <div style={styles.dialog}>
            <ButtonClose
              onClick={() => {
                setConfirmationClearCart(false);
              }}
            >
              <ClearIcon style={{ fontSize: "27px" }} />
            </ButtonClose>
            <p style={styles.text}>
              {" "}
              {t("Minimum order value")} <br /> {t(" for ")} {t(NameMode)}{" "}
              {t("is ")} {OrderMin}
              {getSymbolFromCurrency(currency)}{" "}
            </p>
            <div style={styles.buttonContainer}>
              <button
                style={styles.buttonYes}
                onClick={() => {
                  setConfirmationClearCart(false);
                }}
              >
                OK
              </button>
              {/* <button style={styles.buttonNo} onClick={() =>{setConfirmationClearCart(false)}}>No</button> */}
            </div>
          </div>
        </Dialog>
        <StyledModalcheckout
          isOpen={modalCheckout || modalCheckouts}
          onClose={() => setModalCheckout(false)} // Add onClose handler to close the modal
          className="ModalCheckout"
          overlayClassName="OverlayCheckout"
        >
          <Header>{t("Checkout")}</Header>
          {((stage === 1 && delivery === undefined) || delivery === false) && (
            <>
              <CheckoutContainer>
                <ContentWrapper>
                  <ContactInfo>
                    <Subtitle>{t("Contact")}</Subtitle>
                    <UserInfo>
                      {loggedInGuest ? (
                        <div>
                          <Container22>
                            <UserInfo22>
                              <SectionTitle22>{t("Name")}: </SectionTitle22>
                              {loggedInGuest.lastName} {loggedInGuest.firstName}
                            </UserInfo22>
                            {loggedInGuest.email && (
                              <UserInfo22>
                                <SectionTitle22>{t("Email")}: </SectionTitle22>
                                {loggedInGuest.email}
                              </UserInfo22>
                            )}

                            <PhoneSelector22>
                              <SectionTitle22>
                                {t("Phone Number")}:
                              </SectionTitle22>

                              <PhoneInputNumber
                                defaultCountry="FR"
                                placeholder={t("Enter Phone Number")}
                                value={customPhoneNumber}
                                onChange={handlePhoneNumberChange}
                              />
                            </PhoneSelector22>
                            {errorPhone && (
                              <div style={{ color: "red" }}>
                                {t(errorPhone)}
                              </div>
                            )}
                          </Container22>
                        </div>
                      ) : (
                        <>
                          <Container22>
                            <UserInfo22>
                              <SectionTitle22>{t("Name")}: </SectionTitle22>
                              {loggedInUser.lastName} {loggedInUser.firstName}
                            </UserInfo22>
                            <UserInfo22>
                              <SectionTitle22>{t("Email")}: </SectionTitle22>
                              {loggedInUser.email}
                            </UserInfo22>
                            <PhoneSelector22>
                              <SectionTitle22>
                                {t("Phone Number")}:{" "}
                              </SectionTitle22>

                              <PhoneInputNumber
                                defaultCountry="FR"
                                placeholder={t("Enter Phone Number")}
                                value={customPhoneNumber}
                                onChange={handlePhoneNumberChange}
                              />
                            </PhoneSelector22>
                            {errorPhone && (
                              <div style={{ color: "red" }}>
                                {t(errorPhone)}
                              </div>
                            )}
                          </Container22>
                        </>
                      )}
                    </UserInfo>
                  </ContactInfo>
                  <CouponWrapper>
                    <Label> {t("Have a coupon?")}</Label>

                    <InputWrapper>
                      <Input
                        type="text"
                        value={coupon}
                        onChange={(e) => setCoupon(e.target.value)}
                        placeholder={t("Enter coupon")}
                        disabled={discountCoupon !== null}
                      />
                      {discountCoupon === null && (
                        <Button onClick={handleApply}>{t("Apply")}</Button>
                      )}
                    </InputWrapper>
                    {message && (
                      <Message error={!isApplied}>
                        {message}
                        {/* {isApplied && <RemoveButton onClick={handleRemove}>REMOVE</RemoveButton>} */}
                      </Message>
                    )}
                  </CouponWrapper>
                  {/*------------------------------------- accordion de livraison -------------------------------------*/}
                  {isLivraisonMode && (
                    <Accordion>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>{t("Delivery")}</Accordion.Header>
                        <Accordion.Body>
                          {/* <div style={{margin:"7px 0px"}}>
                    <RoomIcon/>
                    <input type="text" placeholder="Ajouter une nouvelle adresse"/>
                    </div> */}
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <div>
                              <div style={{ marginBottom: "14px" }}>
                                <p
                                  style={{
                                    float: "left",
                                    fontFamily: "QuickSand",
                                    fontSize: "20px",
                                  }}
                                >
                                  {t("Your Addresses")}
                                </p>
                              </div>
                              {/* <div style={{margin:"20px"}}></div> */}
                              <table
                                style={{ width: "100%", marginTop: "16px" }}
                              >
                                <tbody>
                                  {loadingAddress ? (
                                    <tr>
                                      <td>Loading...</td>
                                    </tr>
                                  ) : adresses && adresses.length > 0 ? (
                                    <>
                                      {adresses.map((adresse, index) => (
                                        <>
                                          {/* {console.log(adresses)} */}
                                          <tr
                                            key={index}
                                            style={{
                                              margin: "7px",
                                              cursor: "pointer",
                                              backgroundColor: "#fff9",
                                              borderRadius: "10px",
                                              boxShadow:
                                                selectedAdresse.includes(
                                                  `adresse-${adresse.Nameaddress}-${adresse.Type}`
                                                ) && outOfRange
                                                  ? "rgba(255, 1, 1, 0.856) 0px 0px 0px 1px inset, rgba(255, 1, 1, 0.856) 0px 0px 0px 1px"
                                                  : selectedAdresse.includes(
                                                      `adresse-${adresse.Nameaddress}-${adresse.Type}`
                                                    )
                                                  ? "var(--primaryColor) 0px 0px 0px 1px inset, var(--primaryColor) 0px 0px 0px 1px"
                                                  : "",
                                            }}
                                            id={`adresse-${adresse.Nameaddress}-${adresse.Type}`}
                                            onClick={() => {
                                              store.dispatch(
                                                setDeliveryAdress({
                                                  deliveryAdress:
                                                    adresse.Nameaddress,
                                                })
                                              );
                                              store.dispatch(
                                                setDeliveryInformation({
                                                  deliveryInformation: {
                                                    type: adresse.Type,
                                                    note: adresse.Note,
                                                  },
                                                })
                                              );
                                              store.dispatch(
                                                setSelectedAdresse([
                                                  `adresse-${adresse.Nameaddress}-${adresse.Type}`,
                                                ])
                                              );
                                              store.dispatch(
                                                setLocation({
                                                  label: adresse.Nameaddress,
                                                  value: {
                                                    lat: adresse.Lat,
                                                    lon: adresse.Lon,
                                                  },
                                                })
                                              );
                                              setNewAdresse(false);
                                            }}
                                          >
                                            <td
                                              style={{
                                                width: "50px",
                                                paddingTop: "10px",
                                                borderRadius:
                                                  "10px 0px 0px 10px",
                                              }}
                                            >
                                              <AccessTimeIcon
                                                style={{
                                                  color: "rgb(134, 128, 119)",
                                                }}
                                              />
                                            </td>
                                            <td
                                              style={{
                                                float: "left",
                                                paddingTop: "10px",
                                                fontSize: "16px",
                                                fontFamily: "roboto",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "column",
                                                  alignItems: "flex-start",
                                                  width: "100%",
                                                }}
                                              >
                                                <p
                                                  style={{
                                                    fontFamily: "QuickSandBold",
                                                  }}
                                                >
                                                  {adresse.Type}
                                                </p>
                                                <div>{adresse.Nameaddress}</div>
                                                {adresse.Note !== "" && (
                                                  <div>({adresse.Note})</div>
                                                )}
                                              </div>
                                            </td>
                                            <td
                                              style={{
                                                width: "50px",
                                                paddingTop: "10px",
                                                borderRadius:
                                                  "0px 10px 10px 0px",
                                              }}
                                            >
                                              <DeleteIcon
                                                style={{
                                                  color: "rgb(134, 128, 119)",
                                                }}
                                                onClick={() => {
                                                  handleAddressDelete(
                                                    loggedInUser._id,
                                                    adresse.Nameaddress,
                                                    adresse.Type
                                                  );
                                                }}
                                              />
                                            </td>
                                            {/* <td
                    style={{
                      color: "#000",
                    }}
                  >
                    <DeleteIcon />
                  </td> */}
                                          </tr>
                                        </>
                                      ))}
                                    </>
                                  ) : (
                                    <tr>
                                      <td
                                        colSpan="3"
                                        style={{ margin: "10px 0px" }}
                                      >
                                        <p style={{ color: "#9c9ea5" }}>
                                          {t("empty")}
                                        </p>
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                              {!newAdresse && outOfRange ? (
                                <div style={{ marginTop: "10px" }}>
                                  <span className="out-of-range-message">
                                    {t("this address is out of delivery range")}
                                  </span>
                                </div>
                              ) : (
                                <div></div>
                              )}
                            </div>

                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <NewAdresseButton
                                onClick={() => {
                                  setNewAdresse(true);
                                }}
                              >
                                <AddIcon
                                  style={{ fontSize: "22px", color: "green" }}
                                />
                                {t("Ajouter une adresse")}
                              </NewAdresseButton>
                            </div>
                            <div style={{ display: !newAdresse ? "none" : "" }}>
                              <NewAddresse newAdresse={newAdresse} />
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  )}
                  <Accordion
                  // activeKey={isFocused ? "0" : null}
                  // onSelect={() => setIsFocused(!isFocused)}
                  // onBlur={() => setIsFocused(false)}
                  >
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <span>{t("Your Order")}</span>
                          <div
                            style={{ textAlign: "right", paddingRight: "1rem" }}
                          >
                            {FinaltotalPrice !== totalPrice && (
                              <>
                                {" "}
                                {parseFloat(FinaltotalPrice).toFixed(2)}{" "}
                                {getSymbolFromCurrency(currency)}{" "}
                                <span
                                  style={{ textDecoration: "line-through" }}
                                >
                                  {parseFloat(totalPrice).toFixed(2)}{" "}
                                  {getSymbolFromCurrency(currency)}
                                </span>{" "}
                              </>
                            )}
                            {FinaltotalPrice === totalPrice && (
                              <> {parseFloat(FinaltotalPrice).toFixed(2)} </>
                            )}
                          </div>
                        </div>
                      </Accordion.Header>

                      <Accordion.Body style={{ padding: "0" }}>
                        <OrderContainer>
                          {orders &&
                            orders.length > 0 &&
                            orders.map((order, index) => (
                              <>
                                <OrderItem key={order.product.id}>
                                  <ItemName>
                                    {order.quantity}{" "}
                                    {`${order.product.name} ${
                                      order.product.size !== "S"
                                        ? `(${order.product.size})`
                                        : ""
                                    }`}
                                    <h6
                                      style={{
                                        textAlign: "right",
                                        marginLeft: "auto",
                                        color: "gray",
                                        fontSize: "0.9rem",
                                        fontWeight: "300",
                                      }}
                                    >
                                      {apply === "product"
                                        ? parseFloat(
                                            order.product.price +
                                              (frais !== null
                                                ? frais
                                                : order.product.price *
                                                  (taux / 100))
                                          ).toFixed(2)
                                        : parseFloat(
                                            order.product.price
                                          ).toFixed(2)}
                                      {getSymbolFromCurrency(currency)}
                                    </h6>
                                  </ItemName>

                                  <ProductPanierDescription>
                                    {order.product.description}
                                  </ProductPanierDescription>
                                  {order.options.length > 0 ? (
                                    <Options>
                                      {order.options.map((option, index) => (
                                        <div key={index}>
                                          {index === 0 ||
                                          option.optionGroupeName !==
                                            order.options[index - 1]
                                              .optionGroupeName ? (
                                            <OptionsHeading>
                                              {option.optionGroupeName}
                                            </OptionsHeading>
                                          ) : null}
                                          <ProductOptions>
                                            <div className="flex justify-between">
                                              <Option>
                                                {" "}
                                                {option.quantity > 1 && (
                                                  <> {option.quantity} X </>
                                                )}{" "}
                                                {option.name}
                                              </Option>
                                              {option.price_opt > 0 ? (
                                                <Option>
                                                  {parseFloat(
                                                    option.price_opt *
                                                      option.quantity
                                                  ).toFixed(2)}
                                                  {getSymbolFromCurrency(
                                                    currency
                                                  )}
                                                  {/* options: selectedPromos[i].products[j].options, */}
                                                </Option>
                                              ) : null}
                                            </div>
                                            {option.options.map((opt) => (
                                              <div className="flex justify-between">
                                                <Option>
                                                  &nbsp;+{opt.name}
                                                </Option>
                                                {opt.price > 0 ? (
                                                  <Option>
                                                    {parseFloat(
                                                      opt.price
                                                    ).toFixed(2)}
                                                    {getSymbolFromCurrency(
                                                      currency
                                                    )}
                                                  </Option>
                                                ) : null}
                                              </div>
                                            ))}
                                          </ProductOptions>
                                        </div>
                                      ))}
                                    </Options>
                                  ) : null}
                                  <PriceContainer>
                                    <p>
                                      <b>
                                        SubTotal :{" "}
                                        {parseFloat(order.price).toFixed(2)}
                                        {getSymbolFromCurrency(currency)}
                                      </b>
                                    </p>
                                  </PriceContainer>
                                </OrderItem>
                                {/* <hr /> */}
                              </>
                            ))}

                          {selectedPromos &&
                            selectedPromos.length > 0 &&
                            selectedPromos.map((product, ind) => (
                              <PromoContainer key={product.promo._id}>
                                <PromoTitle key={ind}>
                                  {" "}
                                  {product.promo.name}
                                </PromoTitle>

                                {product.products.map((order, index) => (
                                  <div key={order.product.id}>
                                    <ItemName>
                                      {order.quantity}{" "}
                                      {`${order.product.name} ${
                                        order.product.size !== "S"
                                          ? `(${order.product.size})`
                                          : ""
                                      }`}
                                      <h6 style={{ marginLeft: "auto" }}>
                                        {order.price_after_discount !==
                                          order.price &&
                                        product.promo.discount === 100 ? (
                                          <span>{t("Free")}</span>
                                        ) : order.price_after_discount !==
                                          order.price ? (
                                          <>
                                            <span
                                              style={{
                                                textDecorationLine:
                                                  "line-through",
                                                marginRight: "5px",
                                                color: "#a6a6a6",
                                                fontSize: "0.9rem",
                                              }}
                                            >
                                              {`${parseFloat(
                                                order.product.price
                                              ).toFixed(2)}
                                                                                            ${getSymbolFromCurrency(
                                                                                              currency
                                                                                            )}`}
                                            </span>
                                            <span
                                              style={{
                                                textDecorationLine:
                                                  "line-through",
                                                marginRight: "5px",
                                                color: "#a6a6a6",
                                                fontSize: "0.9rem",
                                              }}
                                            >
                                              {`${
                                                apply === "product"
                                                  ? parseFloat(
                                                      order.product.price -
                                                        (order.product.price *
                                                          product.promo
                                                            .discount) /
                                                          100 +
                                                        (frais !== null
                                                          ? frais
                                                          : order.product
                                                              .price *
                                                            (taux / 100))
                                                    ).toFixed(2)
                                                  : parseFloat(
                                                      order.product.price
                                                    ).toFixed(2)
                                              }
                                              ${getSymbolFromCurrency(
                                                currency
                                              )}`}
                                            </span>
                                          </>
                                        ) : (
                                          `${parseFloat(
                                            order.product.price
                                          ).toFixed(2)}
                                                                                    ${getSymbolFromCurrency(
                                                                                      currency
                                                                                    )}`
                                        )}
                                      </h6>
                                    </ItemName>

                                    <ProductPanierDescription>
                                      {order.product.description}
                                    </ProductPanierDescription>
                                    {order.options.length > 0 ? (
                                      <Options>
                                        {order.options.map((option, index) => (
                                          <div key={index}>
                                            {index === 0 ||
                                            option.optionGroupeName !==
                                              order.options[index - 1]
                                                .optionGroupeName ? (
                                              <OptionsHeading>
                                                {option.optionGroupeName}
                                              </OptionsHeading>
                                            ) : null}
                                            <ProductOptions>
                                              <div className="flex justify-between">
                                                <Option>{option.name}</Option>
                                                {option.price_opt > 0 ? (
                                                  <Option>
                                                    {parseFloat(
                                                      option.price_opt
                                                    ).toFixed(2)}
                                                    {getSymbolFromCurrency(
                                                      currency
                                                    )}
                                                  </Option>
                                                ) : null}
                                              </div>
                                              {option.options.map((opt) => (
                                                <div className="flex justify-between">
                                                  <Option>
                                                    &nbsp;+{opt.name}
                                                  </Option>
                                                  {opt.price > 0 ? (
                                                    <Option>
                                                      {parseFloat(
                                                        opt.price
                                                      ).toFixed(2)}
                                                      {getSymbolFromCurrency(
                                                        currency
                                                      )}
                                                    </Option>
                                                  ) : null}
                                                </div>
                                              ))}
                                            </ProductOptions>
                                          </div>
                                        ))}
                                      </Options>
                                    ) : null}
                                    {/* {apply === "product" && (
                                    <>
                                        <ProductOptions>Extra fees</ProductOptions>
                                        <div className="flex justify-between">
                                            <Option>{modeName} fee </Option>
                                            {frais === 0 || taux === 0 ? (
                                                <Option>Free</Option>
                                            ) : (
                                                <Option>
                                                    {getSymbolFromCurrency(currency)}
                                                    {frais !== null
                                                        ? fraisD
                                                        : parseFloat(order.price).toFixed(2) *
                                                        (taux / 100)}
                                                </Option>
                                            )}
                                        </div>
                                    </>
                                 )} */}

                                    <PriceContainer>
                                      <h6>
                                        {/* <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "flex-end",
                                          }}
                                        >
                                          <div>
                                            <p>
                                              HT :{" "}
                                              {order.price_after_discount !==
                                              order.price
                                                ? parseFloat(
                                                    order.price_after_discount /
                                                      (1 +
                                                        order.product.tax / 100)
                                                  ).toFixed(2)
                                                : parseFloat(
                                                    order.priceHt
                                                  ).toFixed(2)}
                                              {getSymbolFromCurrency(currency)}
                                            </p>
                                          </div>
                                          <div>
                                          {Object.keys(order.taxes).map(
                                          (key) =>
                                            key > 0 && (
                                              <div key={key}>
                                                <p>
                                                  {key}% :{" "}
                                                  {parseFloat(
                                                    order.taxes[key]
                                                  ).toFixed(2)}{" "}
                                                  {getSymbolFromCurrency(
                                                    currency
                                                  )}
                                                </p>
                                              </div>
                                            )
                                        )}
                                          </div>
                                        </div> */}
                                        {apply === "product"
                                          ? parseFloat(
                                              order.price_after_discount +
                                                (frais !== null
                                                  ? frais
                                                  : order.price_after_discount *
                                                    (taux / 100))
                                            ).toFixed(2)
                                          : parseFloat(
                                              order.price_after_discount
                                            ).toFixed(2)}
                                        {getSymbolFromCurrency(currency)}
                                      </h6>
                                    </PriceContainer>
                                  </div>
                                ))}
                                <PriceContainer>
                                  {/* <h5> Total :</h5> */}
                                  <h5>
                                    {parseFloat(
                                      sumTotalInPromo(product, ind)
                                    ).toFixed(2)}
                                    {getSymbolFromCurrency(currency)}
                                  </h5>
                                </PriceContainer>
                              </PromoContainer>
                            ))}
                          <hr />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-end",
                              padding: "0 20px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "flex-end",
                              }}
                            >
                              <div>
                                <p style={{ textAlign: "right" }}>
                                  {t(NameMode + " Fee")} :{" "}
                                  {apply === "product" ? (
                                    "Free"
                                  ) : (
                                    <>
                                      {parseFloat(frais).toFixed(2)}{" "}
                                      {getSymbolFromCurrency(currency)}
                                    </>
                                  )}
                                </p>
                              </div>

                              <div>
                                <p style={{ textAlign: "right" }}>
                                  {t("tax-exclusive")} :{" "}
                                  {parseFloat(taxesHt.Ht).toFixed(2)}{" "}
                                  {getSymbolFromCurrency(currency)}
                                </p>
                              </div>
                              <div style={{ textAlign: "right" }}>
                                {Object.keys(taxesHt.taxes).map(
                                  (key) =>
                                    key > 0 && (
                                      <div key={key}>
                                        <p>
                                          Tax {key}% :{" "}
                                          {parseFloat(
                                            taxesHt.taxes[key]
                                          ).toFixed(2)}{" "}
                                          {getSymbolFromCurrency(currency)}
                                        </p>
                                      </div>
                                    )
                                )}
                              </div>
                              {FinaltotalPrice !== totalPrice && (
                                <div>
                                  <p style={{ textAlign: "right" }}>
                                    {t("Discount coupon")} : {discountCoupon}%
                                  </p>
                                </div>
                              )}
                            </div>

                            <p>
                              <b>
                                {" "}
                                Total :{" "}
                                {FinaltotalPrice !== totalPrice && (
                                  <>
                                    {" "}
                                    {parseFloat(FinaltotalPrice).toFixed(
                                      2
                                    )}{" "}
                                    {getSymbolFromCurrency(currency)}{" "}
                                    <span
                                      style={{ textDecoration: "line-through" }}
                                    >
                                      {parseFloat(totalPrice).toFixed(2)}{" "}
                                      {getSymbolFromCurrency(currency)}
                                    </span>{" "}
                                  </>
                                )}
                                {FinaltotalPrice === totalPrice && (
                                  <>
                                    {" "}
                                    {parseFloat(FinaltotalPrice).toFixed(
                                      2
                                    )}{" "}
                                    {getSymbolFromCurrency(currency)}
                                  </>
                                )}
                              </b>
                            </p>
                          </div>
                        </OrderContainer>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>

                  <AddNote>
                    <Subtitle>{t("kitchen note")}</Subtitle>
                    <TextareaAutosize
                      id="noteTextarea"
                      aria-label=""
                      minRows={3}
                      mincols={20}
                      placeholder={t(
                        "Leave an addioonal note about your food ( not too spicy )"
                      )}
                      style={{
                        width: "100%",
                        border: "1px solid grey",
                        borderRadius: "5px",
                        padding: "1%",
                        fontStyle: "italic",
                      }}
                      onChange={handleNoteChange}
                    />
                  </AddNote>

                  <OrderTime>
                    <Subtitle>{t("Order Time")}</Subtitle>
                    <PaymentOptions>
                      <label>
                        <input
                          type="radio"
                          name="orderTime"
                          value="ASAP"
                          onChange={handleScheduleOrderChange}
                          disabled={
                            isOpenNow(restaurantSelected) ? false : true
                          }
                        />
                        {t("ASAP")}
                      </label>
                      {isOpenNow(restaurantSelected) ? null : (
                        <TextConfirmation2>
                          {" "}
                          {t(
                            "The restaurant is closed, ASAP mode is not available"
                          )}
                          .
                        </TextConfirmation2>
                      )}
                      <label>
                        <input
                          type="radio"
                          name="orderTime"
                          value="ScheduleOrder"
                          onChange={handleScheduleOrderChange}
                        />
                        {t("Schedule Order")}
                        <br></br>
                        {selectedOrderTime === "ScheduleOrder" && (
                          <>
                            <DatePickerContainer>
                              <DatePicker
                                selected={selectedDate}
                                onChange={handleDateChange}
                                minDate={new Date()}
                                maxDate={
                                  new Date(
                                    new Date().setDate(new Date().getDate() + 6)
                                  )
                                }
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                dateFormat="MMMM d, yyyy h:mm aa"
                                filterTime={filterTime}
                                customInput={<CustomInput />}
                                locale="en" // Assuming English locale
                                className="datepicker-left"
                              />
                            </DatePickerContainer>
                            <ClearButtonTime
                              className=""
                              onClick={clearDate}
                              disabled={
                                !selectedDate ||
                                availableTimeOptions.length === 0
                              }
                            >
                              <RotateLeftIcon />
                            </ClearButtonTime>
                          </>
                        )}
                      </label>
                    </PaymentOptions>
                    {errorTimeorder && (
                      <div style={{ color: "red" }}>{t(errorTimeorder)}</div>
                    )}
                  </OrderTime>
                  <PaymentInfo>
                    <Subtitle>{t("Payment Method")}</Subtitle>
                    <PaymentOptions>
                      <label>
                        <input
                          type="radio"
                          name="paymentMethod"
                          value="cash"
                          onChange={handlePaymentMethodChange}
                        />
                        💵 {t("Cash")}{" "}
                      </label>
                      <label>
                        <input
                          type="radio"
                          name="paymentMethod"
                          value="EPT"
                          onChange={handlePaymentMethodChange}
                        />
                        <PointOfSaleIcon />
                        {t("EPT")}
                      </label>
                      {restaurantSelected.paiementEnLigne ? (
                        <label>
                          <input
                            type="radio"
                            name="paymentMethod"
                            value="Credit/Debit Card"
                            onChange={handlePaymentMethodChange}
                          />
                          💳 {t("Credit/Debit Card")}
                        </label>
                      ) : null}
                    </PaymentOptions>
                  </PaymentInfo>
                  {selectedPaymentMethod === "Credit/Debit Card" && (
                    <div style={{ marginLeft: "10px" }}>
                      <CardElement
                        options={{
                          style: {
                            base: {
                              fontSize: "16px",
                              color: "#424770",
                              "::placeholder": {
                                color: "#AAB7C4",
                              },
                            },
                            invalid: {
                              color: "#9E2146",
                            },
                          },
                        }}
                      />
                      {error && <div style={{ color: "red" }}>{error}</div>}
                    </div>
                  )}
                  {errorMethodpaiement && (
                    <div style={{ color: "red" }}>{t(errorMethodpaiement)}</div>
                  )}
                </ContentWrapper>

                <CloseButton onClick={closeCheckoutModal}></CloseButton>
              </CheckoutContainer>
              <div
                style={{
                  marginBottom: "10px",
                  //position: "sticky",
                  //bottom: "-2px",
                  //width: "auto",
                  //background: "#fff",
                  //margin: 0,
                  //padding: "10px",
                }}
              >
                {loggedInGuest === undefined && isLivraisonMode && (
                  <AddNote>
                    <Subtitle>{t("Delivery note")}</Subtitle>
                    <TextareaAutosize
                      id="noteTextarea"
                      aria-label=""
                      minRows={3}
                      mincols={20}
                      placeholder={t("Leave a note for your delivery")}
                      style={{
                        width: "100%",
                        border: "1px solid grey",
                        borderRadius: "5px",
                        padding: "1%",
                        fontStyle: "italic",
                      }}
                      onChange={handleDeliveryNoteChange}
                    />
                  </AddNote>
                )}
                <StoresConfirmation
                  ref={storesConfirmationRef}
                  className="stores-confirmation"
                >
                  <input
                    type="checkbox"
                    checked={isNotChecked}
                    onChange={handleCheckboxChange}
                  />
                  <TextConfirmation onClick={handleCheckboxChange}>
                    {" "}
                    {t("By checking this box, you confirm your order to")}{" "}
                    <b>{restaurantSelected.name}</b>.{" "}
                    {t(
                      "This action is irreversible and commits to the purchase as outlined."
                    )}
                  </TextConfirmation>
                </StoresConfirmation>
                {processingP === false && (
                  <OrderButton
                    onClick={handleOrder}
                    disabled={isButtonDisabled}
                    style={{
                      backgroundColor:
                        (isOpenNow(restaurantSelected) &&
                          selectedOrderTime === "ASAP") ||
                        (isOpenNow(restaurantSelected) &&
                          selectedOrderTime === "ScheduleOrder" &&
                          selectedDate != null) ||
                        (!isOpenNow(restaurantSelected) && selectedDate != null)
                          ? "var(--primaryColor)"
                          : "#DCDCDC",
                      color: "white", // Use template literals only if necessary
                    }}
                  >
                    {t("Place Your Order")}
                  </OrderButton>
                )}
                {processingP === true && (
                  <OrderSpineer>processing ....</OrderSpineer>
                )}

                <ToastContainer />

                {/* {deliveryError !== "" && (
                  <div style={{ color: "red" }}>{deliveryError}</div>
                )} */}
              </div>
            </>
          )}

          {delivery === undefined && stage === 2 && (
            <div>
              {delivery === undefined &&
              OrderNotification.find(
                (notification) => notification.orderId === timer?.orderId
              ) ? (
                <div>
                  {OrderNotification.find(
                    (notification) => notification.orderId === timer.orderId
                  ).status !== "accepted" && (
                    <CloseButton onClick={closeCheckoutModal}></CloseButton>
                  )}
                  <div>
                    {delivery === undefined &&
                      (OrderNotification.find(
                        (notification) => notification.orderId === timer.orderId
                      ).status === "rejected" ||
                        OrderNotification.find(
                          (notification) =>
                            notification.orderId === timer.orderId
                        ).status === "missed") && (
                        <div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Lottie
                              style={{
                                width: "30%",
                              }}
                              animationData={rejectData}
                              loop={false}
                            />
                            {delivery === undefined &&
                              OrderNotification.find(
                                (notification) =>
                                  notification.orderId === timer.orderId
                              ).status === "missed" && (
                                <TextMissed>
                                  {t(
                                    "We regret to inform you that your order has been Missed"
                                  )}
                                  {/* <button onClick={closeCheckoutModal}  className="btn">done</button> */}
                                </TextMissed>
                              )}
                            {delivery === undefined &&
                              OrderNotification.find(
                                (notification) =>
                                  notification.orderId === timer.orderId
                              ).status === "rejected" && (
                                <TextRejected>
                                  {t(
                                    "We regret to inform you that your order has been Rejected"
                                  )}
                                  {/* <button onClick={closeCheckoutModal} className="btn">done</button> */}
                                </TextRejected>
                              )}
                            <br></br>
                            <br></br>
                            <Repeter onClick={handlesetStage}>
                              Re-Order{" "}
                            </Repeter>
                          </div>
                        </div>
                      )}

                    {delivery === undefined &&
                      OrderNotification.find(
                        (notification) => notification.orderId === timer.orderId
                      ).status === "accepted" && (
                        <div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Lottie
                              style={{
                                width: "40%",
                              }}
                              animationData={validData}
                              loop={false}
                            />
                            <TextAccept>
                              {t(
                                "We are pleased to inform you that your order has been Accepted"
                              )}
                              .
                            </TextAccept>
                            <CloseButton
                              onClick={closeCheckoutModal}
                            ></CloseButton>
                            <br />
                            <br />
                          </div>
                        </div>
                      )}
                    {delivery === undefined &&
                      OrderNotification.find(
                        (notification) => notification.orderId === timer.orderId
                      ).status === "ready" && (
                        <div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Lottie
                              style={{
                                width: "40%",
                              }}
                              animationData={ready}
                              loop={false}
                            />
                            <TextReady>
                              {t(
                                "We are pleased to inform you that your order is Ready"
                              )}
                              .
                            </TextReady>
                            <br></br>
                            <br></br>

                            <QrCode
                              style={{ width: "32%", height: "32%" }}
                              value={timer.orderId}
                            />
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              ) : timer?.timeRemaning ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <TextWait>
                    {t("Your order is waiting for the restaurant's approval.")}{" "}
                    <br />
                    {t("Thanks for your patience!")}
                  </TextWait>
                  <Lottie
                    style={{
                      width: "40%",
                      marginTop: "16%",
                    }}
                    animationData={waitData}
                    loop={true}
                  />
                  <TextWait>{timer?.timeRemaning}</TextWait>
                </div>
              ) : null}
            </div>
          )}
        </StyledModalcheckout>
      </div>
    )
  );
}

function ReactModalAdapter({ className, modalClassName, ...props }) {
  return (
    <Modal className={modalClassName} portalClassName={className} {...props} />
  );
}
const StoresConfirmation = styled.div`
  display: flex;
  margin-left: 4px;
  margin-top: 30px;
  border: 2px solid white;
  border-radius: 10px;
  padding: 7px;

  &.red-border {
    border: 2px solid red;
    border-radius: 10px;
  }
`;
const Repeter = styled.button`
  background: linear-gradient(
    145deg,
    #4caf50,
    #388e3c
  ); /* Green gradient to complement #ff435a */
  color: white; /* White text for contrast */
  padding: 20px 40px; /* Larger padding for better user interaction */
  font-size: 18px; /* Readable font size */
  font-weight: 700; /* Bold text for emphasis */
  border-radius: 15px; /* Rounded corners for a soft modern look */
  border: none; /* No border */
  cursor: pointer; /* Pointer cursor on hover */
  text-transform: uppercase; /* Uppercase text for boldness */
  transition: all 0.4s ease-in-out; /* Smooth transitions for better feel */
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.2); /* Deep shadow for a floating effect */

  /* Hover effect: slightly enlarge and change gradient */
  &:hover {
    background: linear-gradient(
      145deg,
      #388e3c,
      #4caf50
    ); /* Invert gradient on hover */
    transform: scale(1.1) translateY(-6px); /* Larger scale and lift effect */
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.25); /* Deeper shadow on hover */
  }

  /* Focus effect: glowing outline for accessibility */
  &:focus {
    outline: none; /* Remove default outline */
    box-shadow: 0 0 0 4px rgba(76, 175, 80, 0.8); /* Soft glow outline for focus */
  }

  /* Active state: simulate pressing the button */
  &:active {
    background: linear-gradient(
      145deg,
      #2c6c2f,
      #4caf50
    ); /* Darker gradient for active state */
    transform: scale(0.98) translateY(3px); /* Pressed effect (slightly shrink and sink) */
    box-shadow: inset 0 6px 12px rgba(0, 0, 0, 0.1); /* Inset shadow for pressed look */
  }

  /* Disabled state: faded and unresponsive appearance */
  &:disabled {
    background: #e0e0e0; /* Gray background when disabled */
    cursor: not-allowed; /* Block pointer cursor */
    opacity: 0.6; /* Lower opacity to indicate inactivity */
    box-shadow: none; /* Remove shadow on disabled state */
  }
`;
const TextConfirmation = styled.p`
  font-family: "QuickSand";
  line-height: normal;
  margin-left: 10px !important;
  width: fit-content;
  text-align: left;
`;
const TextConfirmation2 = styled.p`
  font-family: "QuickSand";
  line-height: normal;
  font-size: 0.8rem;
  color: red;
  margin: -14px 0 12px 40px !important;

  width: fit-content;
  text-align: left;
`;

const StyledModalcheckout = styled(ReactModalAdapter).attrs({
  modalClassName: "ModalCheckout",
  overlayClassName: "OverlayCheckout",
})`
  .ModalCheckout {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 20px 20px 0 20px;
    border-radius: 15px;
    height: 90%;
    overflow-y: auto;
    min-height: 500px;
    width: 500px;
    text-align: center;
    outline: none;
  }
  .ModalCheckout::-webkit-scrollbar {
    width: 0px;
  }
  @media (max-width: 768px) {
    .ModalCheckout {
      width: 100%;
      height: 100%;
      max-height: 100%;
    }
  }

  .OverlayCheckout {
    background: rgba(0, 0, 0, 0.2);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
`;

const CheckoutContainer = styled.div`
  // display:flex;
  // justify-content:space-between;
`;
const Header = styled.h1`
  color: var(--primaryColor);
  margin-bottom: 20px;
`;

const ContentWrapper = styled.div`
  width: 100%;
`;

const Subtitle = styled.h4`
  margin-bottom: 10px;
  margin-top: 20px;
  width: 100%;
`;

const ContactInfo = styled.div`
  text-align: left;
`;

const UserInfo = styled.div`
  margin-bottom: 20px;
`;

const AddNote = styled.div`
  text-align: left;
`;

const OrderTime = styled.div`
  text-align: left;
`;

const ClearButtonTime = styled.button`
  background: red;
  color: white;
  padding: 2px;
  border: 1px solid red;
  height: 26px;
  //   width: 48%;
  margin-left: -10px;
  padding: 0;
  font-size: 14px;
  border-radius: 0px 5px 5px 0px;
  &:hover {
    background: white;
    color: red;
  }
  &:disabled {
    opacity: 0.5; /* Adjust opacity or any other styles for the disabled state */
    cursor: not-allowed; /* Change cursor to indicate the button is disabled */
  }
`;
const PaymentInfo = styled.div`
  text-align: left;
`;

const PaymentOptions = styled.div`
  label {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    input {
      margin-right: 10px;
    }
  }
`;
const OrderContainer = styled.div`
  //   width: 100%;
  //   height: fit-content;
  //   box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
`;

const OrderButton = styled.button`
  // background-color: var(--primaryColor);
  // color: #ffffff;
  border: none;
  border-radius: 25px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
`;
const OrderSpineer = styled.div`
  // background-color: var(--primaryColor);
  // color: #ffffff;

  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  width: 30px;
  height: 30px;
  cursor: pointer;
  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 2px;
    height: 24px;
    background: #000;
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
`;

//cart
const OrderItem = styled.div`
  background-color: #fff;
  border-top: 1px solid gray;
  padding: 5px;
`;

const ItemName = styled.div`
  display: flex;

  text-align: left;
  width: 100%;
  font-size: 0.9rem;
`;

const ProductPanierDescription = styled.p`
  text-align: left;
  font-size: 14px;
  font-style: italic;
  margin-bottom: 10px;
  color: #666;
`;

const Options = styled.div`
  text-align: left;
  margin-bottom: 10px;
`;

const ProductOptions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const OptionsHeading = styled.h6`
  font-size: 0.8rem;
  font-weight: bold;
  margin-bottom: 5px;
`;

const Option = styled.p`
  font-size: 14px;
  font-style: italic;
  margin-bottom: 5px;
  color: #666;
`;

const PriceContainer = styled.div`
  float: right;
  text-align: right;
  margin-left: auto;
  width: 100%;
  font-size: 14px;
  color: #000;
`;

const PromoTitle = styled.h6`
  margin-left: 3px;
  font-size: 20px;
  border-radius: 10px;
  padding: 5px;
  text-align: center;
`;

const PromoContainer = styled.div`
  // margin-bottom: 20px;

  background-color: #fff;
  border-top: 1px solid gray;
  padding: 5px;
`;

const TextWait = styled.div`
  margin-top: 30px;
  color: #eb1700;
`;
const TextAccept = styled.div`
  margin-top: 30px;
  color: #67af65;
`;
const TextReady = styled.div`
  margin-top: 30px;
  color: #67af65;
`;
const TextMissed = styled.div`
  margin-top: 30px;
  color: #ff435a;
`;
const TextRejected = styled.div`
  margin-top: 30px;
  color: #ff435a;
`;

const NewAdresseButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid lightgrey;
  border-radius: 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  width: 100%;
  height: 50px;
  margin: 16px 0px;
  cursor: pointer;
`;

const styles = {
  dialog: {
    // padding: '30px',
    borderRadius: "8px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    backgroundColor: "#fff",
    width: "350px",
    height: "150px",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  text: {
    marginBottom: "10px",
    fontFamily: "Roboto Medium",
    fontWeight: "bold",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "15px",
  },
  buttonYes: {
    background: "#EB1700",
    color: " #fff",
    border: " none",
    borderRadius: "15px",
    fontSize: "18px",
    marginLeft: "10px",
    padding: "10px",
    width: "116px",
    height: "44px",
  },
  buttonNo: {
    padding: "10px 20px",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    backgroundColor: "#f11c1c",
  },
};
const ButtonClose = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  padding-right: 2px;
  cursor: pointer;
  background-color: white;
  margin-top: 2px;
`;
const Container22 = styled.div`
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
`;

const UserInfo22 = styled.div`
  margin-bottom: 10px;
  font-size: 14px;
`;

const SectionTitle22 = styled.strong`
  font-size: 15px;
`;

const PhoneSelector22 = styled.div`
  margin-top: 15px;
`;

const DatePickerContainer = styled.div`
  .react-datepicker-popper {
    left: -68px !important;
  }
`;

const PhoneInputNumber = styled(PhoneInput)`
  border-radius: 4px;
  padding: 6px 12px;
  font-size: 14px;
  height: 10%;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 0px;
  width: 91%;
  border-radius: 25px;
  display: flex;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;

const CouponWrapper = styled.div`
  border: 0.5px solid #ced4da;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 8px;
  width: "100%";
  background-color: white;
  position: relative;
`;
const Label = styled.div`
  font-size: 16px;
  margin-bottom: 8px;
  font-weight: bold;
  display: flex;
  align-items: center;
`;

const Icon = styled.span`
  margin-right: 6px;
  font-size: 18px;
`;
const InputWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Input = styled.input`
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px 0 0 4px;
  outline: none;
  font-size: 14px;
`;

const Button = styled.button`
  padding: 8px 16px;
  background-color: gray;
  color: white;
  border: none;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    background-color: darkgray;
  }
`;

const Message = styled.div`
  margin-top: 8px;
  font-size: 14px;
  color: ${(props) => (props.error ? "red" : "green")};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const RemoveButton = styled.button`
  color: red;
  background: none;
  border: none;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
`;
export default Checkout;
