import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import i18n from '../../i18n';
import { store } from '../../shared';
import { setScroll } from '../../shared/slice/scroll/ScrollSlice';
import { disconnect } from '../../shared/slice/auth/AuthSlice';
import { setModelGuest } from '../../shared/slice/ModalLogin/ModalLoginSlice';
import { setNotification } from '../../shared/slice/ModalNotification/ModalNotification';
import {
  Drawer, List, ListItem, ListItemText, Divider, IconButton, Select, MenuItem, FormControl,
  Typography, Box, InputLabel, Avatar, Tooltip, Fade
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LanguageIcon from '@mui/icons-material/Language';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import HistoryIcon from '@mui/icons-material/History';
import LogoutIcon from '@mui/icons-material/Logout';

export default function SideNavBar() {
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language.split('-')[0]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { t } = useTranslation();
  const isLoggedIn = useSelector((state) => state.authentification.isLoggedIn);
  const selectedRestaurant = useSelector((state) => state.restaurant?.restaurantSelected?._id);
  const loggedInGuest = useSelector((state) => state.authentification.loggedInGuest);
  const user = useSelector((state) => state.authentification.loggedInUser);
  const navigate = useNavigate();
  const location = useLocation();
  const { delivery } = location.state || {};

  const handleLanguageChange = (e) => {
    const newLang = e.target.value;
    i18n.changeLanguage(newLang);
    setSelectedLanguage(newLang);
  };

  const handleNavToOrdersHistory = () => {
    if(delivery === undefined) {
      navigate(`/ordershistory/${selectedRestaurant}`);
    } else {
      navigate(`/ordershistory/${selectedRestaurant}`, { state: { delivery: false } });
    }
  };

  const logout = () => {
    console.log("wessdisconnect");
    store.dispatch(disconnect());
    store.dispatch(setModelGuest({ ModelGuest: false }));
    store.dispatch(setNotification({ Notification: [] }));
    store.dispatch(setScroll({ scroll: 0 }));
  };

  useEffect(() => {
    store.dispatch(setScroll({ scroll: drawerOpen ? 1 : 0 }));
  }, [drawerOpen]);

  return (
    <>
      {/* Drawer Toggle Button */}
      <IconButton onClick={() => setDrawerOpen(true)} sx={{ color: 'red', fontSize: '2rem' }}>
        <MenuIcon />
      </IconButton>

      {/* Side Drawer */}
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        sx={{
          '& .MuiDrawer-paper': {
            width: 280,
            backgroundColor: '#ffffff',
            color: '#222',
            padding: '1.5rem',
            borderTopLeftRadius: 10,
            borderBottomLeftRadius: 10,
            boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.15)',
            transition: 'width 0.3s ease',
          },
        }}
      >
        <Box textAlign="center" sx={{ marginBottom: 3 }}>
          {isLoggedIn ? (
            <Box display="flex" flexDirection="column" alignItems="center" gap={1}>
              <Avatar sx={{ bgcolor: 'red', width: 56, height: 56, fontSize: '1.5rem' }}>
                {user?.firstName?.charAt(0) || loggedInGuest?.firstName?.charAt(0)}
              </Avatar>
              <Typography variant="h6" fontWeight="bold" color="textPrimary">
                {user?.firstName || loggedInGuest?.firstName}
              </Typography>
            </Box>
          ) : (
            <Typography variant="h6" fontWeight="bold" color="textPrimary">{t('Menu')}</Typography>
          )}
        </Box>

        <Divider sx={{ backgroundColor: '#eb1700', marginY: 2 }} />

        {/* Language Selector */}
        <ListItem>
          <LanguageIcon sx={{ color: '#eb1700', marginRight: 1 }} />
          <FormControl fullWidth>
  <Select
    value={selectedLanguage}
    onChange={handleLanguageChange}
    sx={{
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none', // Removes the border
      },
      '& .MuiSelect-select': {
        color: '#eb1700', // Always sets text color to #eb1700
        paddingY: '12px',
      },
      '& .MuiSvgIcon-root': {
        color: '#eb1700', // Sets icon color to #eb1700
      },
    }}
    aria-label="Language selector" // For accessibility
    inputProps={{
      'aria-label': 'Language selector', // For accessibility
    }}
  >
    <MenuItem value="en">English</MenuItem>
    <MenuItem value="es">Español</MenuItem>
    <MenuItem value="it">Italiano</MenuItem>
    <MenuItem value="fr">Français</MenuItem>
  </Select>
</FormControl>


        </ListItem>

        <Divider sx={{ backgroundColor: '#eee', marginY: 2 }} />

        {/* Menu Items */}
        {isLoggedIn && (
          <>
            {loggedInGuest === undefined && location.pathname !== '/' && (
              <ListItem
                button
                onClick={handleNavToOrdersHistory}
                sx={{
                  paddingY: 1.5,
                  '&:hover': {
                    backgroundColor: '#ffeeee',
                    transform: 'scale(1.02)',
                    transition: 'all 0.2s ease-in-out',
                  },
                }}
              >
                <HistoryIcon sx={{ color: 'red', marginRight: 2 }} />
                <ListItemText primary={t('History')} sx={{ fontSize: '1rem' }} />
              </ListItem>
            )}

            <ListItem
        
              onClick={logout}
              sx={{
                paddingY: 1.5,
                '&:hover': {
                  backgroundColor: '#ffeeee',
                  transform: 'scale(1.02)',
                  transition: 'all 0.2s ease-in-out',
                },
              }}
            >
              <LogoutIcon sx={{ color: 'red', marginRight: 2 }} />
              <ListItemText primary={t('Logout')} sx={{ fontSize: '1rem' }} />
            </ListItem>
          </>
        )}
      </Drawer>
    </>
  );
}
