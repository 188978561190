import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useSelector } from "react-redux";
import { getfavoritesorders, managefavorites } from "../../../../../shared/slice/order/OrderService";
import { store } from "../../../../../shared";
import { setFavoritesOrders } from "../../../../../shared/slice/order/OrderSlice";
import { styled as styledmui } from '@mui/material/styles';
import styled from "styled-components";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import getSymbolFromCurrency from "currency-symbol-map";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import FavoritesOrdersModal from "./FavoritesOrdersModal/FavoritesOrdersModal";
import { reformulateItems, reformulatePromo } from "../../../../../utils/utils-functions";
import MyNavBar from "../../../../../components/Navbar/MyNavBar";
import StarIcon from '@mui/icons-material/Star';
import { lighten } from "polished";

export default function FavoritesOrders() {
    const navigate = useNavigate()

    const user = useSelector((state) => state.authentification.loggedInUser)
    const Favorites = useSelector((state) => state.order.Favorites)
    useEffect(() => {
        if (user) {
            const fetchfavoritesorders = async () => {
                await getfavoritesorders(user._id).then(response => {
                    store.dispatch(setFavoritesOrders({ Favorites: response.orders }))
                }).catch(err => {
                })
            }
            fetchfavoritesorders()
        }
        else {
            navigate(`/page404`)
        }
    }, [user])

    const restaurantSelected = useSelector((state) => state.restaurant.restaurantSelected);
    // console.log(restaurantSelected);
    document.documentElement.style.setProperty("--primaryColor", restaurantSelected.primairecolor);
    document.documentElement.style.setProperty(
        "--primaryColorLight",
        lighten("0.3", restaurantSelected.primairecolor)
    );

    return (
        Favorites && <div>
            <MyNavBar />
            {/* < /> */}
            <PaginatedItems itemsPerPage={10} Favorites={Favorites} />
        </div>

    )

}
// const items = [...Array(33).keys()];

function Items({ currentItems }) {

    const StyledTableCell = styledmui(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            padding: 5,
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            padding: 5,
            fontSize: 14,
        },
    }));

    const StyledTableRow = styledmui(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    const currency = useSelector((state) => state.restaurant.menu.currency)
    const [order, setOrder] = useState({
        state: false,
        data: null
    })
    const user = useSelector((state) => state.authentification.loggedInUser)

    return (
        <div>
            {order.state && <FavoritesOrdersModal order={order} setOrder={setOrder} />}
            <TableContainer component={Paper}>
                <Table aria-label="customized table">
                    <TableHead >
                        <TableRow >
                            {/* <StyledTableCell align="left" style={{
                                backgroundColor: "#444",
                            }}>ID</StyledTableCell> */}
                            <StyledTableCell style={{
                                backgroundColor: "#444"
                            }} align="left">TIME</StyledTableCell>
                            <StyledTableCell style={{
                                backgroundColor: "#444"
                            }} align="left">MODE</StyledTableCell>
                            <StyledTableCell style={{
                                backgroundColor: "#444"
                            }} align="left">STATUS</StyledTableCell>
                            <StyledTableCell style={{
                                backgroundColor: "#444"
                            }} align="left">PAYMENT</StyledTableCell>
                            <StyledTableCell style={{
                                backgroundColor: "#444"
                            }} align="left">TOTAL</StyledTableCell>
                            <StyledTableCell style={{
                                backgroundColor: "#444"
                            }} align="left">VIEW</StyledTableCell>
                            <StyledTableCell style={{
                                backgroundColor: "#444"
                            }} align="left">FAV</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody >
                        {currentItems.map((row) => (
                            <StyledTableRow key={row._id}>
                                {/* <StyledTableCell align="left" component="th" scope="row">
                                    {row._id.slice(row._id.length - 4, row._id.length)}
                                </StyledTableCell> */}
                                <StyledTableCell align="left">{new Date(row.createdAt).toISOString().slice(0, 10)}<br />{new Date(row.createdAt).toISOString().slice(11, 16)}</StyledTableCell>
                                <StyledTableCell align="left">{row.type}</StyledTableCell>
                                <StyledTableCell align="left">{row.status}</StyledTableCell>
                                <StyledTableCell align="left">{row.paymentStatus}</StyledTableCell>
                                <StyledTableCell align="left">{row.price_total}{getSymbolFromCurrency(currency)}</StyledTableCell>
                                <StyledTableCell align="left"><div style={{ cursor: "pointer", color: "#444" }} onClick={() => {
                                    // console.log(row);
                                    let newData = {
                                        ...row,
                                        items: reformulateItems(row.items),
                                        promo: reformulatePromo(row.promo),
                                    }
                                    setOrder({
                                        state: !order.state, data: newData
                                    })
                                }}><RemoveRedEyeIcon /></div></StyledTableCell>
                                <StyledTableCell style={{ cursor: "pointer" }} align="left" onClick={async () => {
                                    await managefavorites({ orderId: row._id, favorite: !row.favorite, userId: user._id }).then(response => {
                                        store.dispatch(setFavoritesOrders({ Favorites: response.orders }))
                                    }).catch(err => {
                                    })
                                }}><StarIcon style={{ color: "gold" }} /></StyledTableCell>

                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

function PaginatedItems({ itemsPerPage, Favorites }) {

    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);

    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        setCurrentItems(Favorites.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(Favorites.length / itemsPerPage));
    }, [itemOffset, itemsPerPage, Favorites]);

    const handlePageClick = (event) => {
        const newOffset = event.selected * itemsPerPage % Favorites.length;
        // console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
        setItemOffset(newOffset);
    };

    return (
        <>
            {currentItems && <Container>
                <Items currentItems={currentItems} />
            </Container>}
            <div style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}>
                <Pagination>
                    <ReactPaginate
                        nextLabel=">"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        pageCount={pageCount}
                        previousLabel="<"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                        renderOnZeroPageCount={null}
                    />
                </Pagination>
            </div>
        </>
    );
}


const Pagination = styled.div`
margin-top : 10px;
.page-item.active .page-link {
    background-color: #eb1700 !important;
    border-color: #fff !important;
}

.page-link {
    color: #444 !important;
    /* border : none !important; */
    /* border-radius: 5px; */
}

.page-link:hover {
    color: #444 !important;
}

`;

const Container = styled.div`
    margin-top: 1%;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 1%;
    @media (max-width: 768px) {
        margin-left: 0;
        margin-right: 0;
    }
`;
