export default {
    //home.js
    "Our Partners": "I nostri partner",

    //AdressInput.js
    "type your address": "inserisci il tuo indirizzo",

    //StoreScreen.js
    "km away": " km ",
    "Closed 🔴": "Chiuso 🔴",
    "Open 🟢": "Aperto 🟢",

    //DateModel.js
    "Monday": "Lunedì",
    "Tuesday": "Martedì",
    "Wednesday": "Mercoledì",
    "Thursday": "Giovedì",
    "Friday": "Venerdì",
    "Saturday": "Sabato",
    "Sunday": "Domenica",
    "Closed": "Chiuso",
    "Service Hours": "Orari di servizio",
    "Language": "lingua",

    //menu.js
    "Our promos": "Le nostre promozioni",

    //selectoption.js
    "(optional)": "(Opzionale)",
    "Required": "Obbligatorio",
    "select up to": "seleziona fino a",

    //SubmitOrder.js
    "select at least": "seleziona almeno",
    "of": "di",
    "Quantity": "Quantità",
    "change your order": "cambia il tuo ordine",

    //submitOrderPromo.js
    "Finish": "Finitura",
    "Next": "Prossimo",

    //ModelPrincipal.js
    "Email Address": "Indirizzo email",
    "Continue": "Continua",
    "Or": "Oppure",
    "Continue with Google": "Continua con Google",
    "Email does not exist": "L'email non esiste",

    //ModalSignup.js
    "First Name": "Nome",
    "Last Name": "Cognome",
    "Email": "Email",
    "Enter Phone Number": "Inserisci numero di telefono",
    "Password": "Password",
    "Confirm Password": "Conferma password",
    "Passwords do not match": "Le password non corrispondono",
    "Signup": "Registrati",
    "Sign up": "Registrati",
    "Male": "Maschio",
    "Female": "Femmina",
    "All fields are required": "Tutti i campi sono obbligatori",
    "One uppercase": "Una lettera maiuscola",
    "One number": "Un numero",
    "Min 8 characters": "Minimo 8 caratteri",
    "One special character": "Un carattere speciale",

    //ModelLogin.js
    "Login": "Accedi",
    "Sign in": "Accedi",
    "Forget password": "Password dimenticata",
    "Add to cart": "Aggiungi al carrello",
    "kitchen note": "Nota cucina",
    "Leave an addioonal note about your food (not too spicy)": "Lascia una nota aggiuntiva sulla tua cucina (non troppo piccante)",

    //Cart.js
    "Your Order": "Il tuo ordine",
    "Checkout": "Pagamento",
    "tax-exclusive": "Esclusa tassa",
    "ASAP": "il prima possibile",
    "By checking this box, you confirm your order to": "Selezionando questa casella, confermi il tuo ordine a",
    "This action is irreversible and commits to the purchase as outlined.": "Questa azione è irreversibile e impegna all'acquisto come descritto.",
    "Minimum order value": "Valore minimo dell'ordine",
    " for ": " per modalità",
    "is ": "è ",

    //checkout.js
    "Contact": "Contatto",
    "Name": "Nome",
    "Email": "Email",
    "phone number": "numero di telefono",
    "Order in Advance": "Ordina in anticipo",
    "Reset": "Ripristina",
    "Month D, YYYY HH:MM": "Mese G, AAAA HH:MM",
    "Payment Method": "Metodo di pagamento",
    "Cash": "Contante",
    "EPT": "TPE",
    "Credit/Debit Card": "Carta di credito / debito",
    "Place Your Order": "Fai il tuo ordine",
    "Dine-in Fee": "Commissione per mangiare sul posto",
    "Takeaway Fee": "Commissione per asporto",
    "Delivery Fee": "Commissione di consegna",
    "Dine-in fees": "Commissioni per mangiare sul posto",
    "Takeaway fee": "Commissione per asporto",
    "Delivery fee": "Commissione di consegna",
    "Delivery note": "Nota per la consegna",
    "Leave a note for your delivery": "Lascia una nota per la consegna",
    "Your Addresses": "I tuoi indirizzi",
    "Friend": "Amico",
    "Home": "Casa",
    "Work": "Lavoro",
    "Other": "Altro",
    "Note for the delivery man:": "Nota per il fattorino",
    " Ex : Building or entry code": " Es: Edificio o codice d'ingresso",
    "Save": "Salva",

    //modalresetPassword.js
    "Email has been send, check your email!": "E-mail inviata, controlla la tua email!",
    "Resend Email": "Rinvia email",

    //firstPage.js
    "All Stores": "Tutti i ristoranti",
    'Nearby Stores': "Ristoranti nelle vicinanze",

    //ModelEmailSend.js
    "Email has been send, check your email!": "E-mail inviata, controlla la tua email!",
    "Resend Email": "Rinvia email",

    //AllStoresScreen.js
    "All Stores": "Tutti i ristoranti",

    //ModalModes.js
    "Select Your Mode": "Seleziona la tua modalità",
    "Yes": "Sì",
    "No": "No",

    //SelectStore.js
    "Select Store": "Seleziona ristorante",
    "Confirm": "Conferma",
    "Delivery": "Consegna",
    "Takeaway": "Da asporto",
    "Dine-in": "sul posto",

    //ProductModel
    "leave your additional note": "lascia una nota aggiuntiva",
    "Additional note": "Nota aggiuntiva",

    //SubmitOrder.js
    "select at least": "seleziona almeno",
    "of": "di",
    "Quantity": "Quantità",

    //OrdersHistory
    "TIME": "TEMPO",
    "MODE": "MODALITÀ",
    "STATUS": "STATO",
    "PAYMENT": "PAGAMENTO",
    "TOTAL": "TOTALE",
    "VIEW": "VEDI",
    "accepted": "accettato",
    "ready": "pronto",
    "rejected": "rifiutato",
    "missed": "perso",
    "pending": "In attesa",

    //OrderHistoryModal.js
    "(Without service fees)": "(Senza tasse di servizio)",
    "ORDER ID": "ID ORDINE",
    "Total price": "Prezzo totale",
    "Add note": "Aggiungi una nota",
    "Leave your note here": "Lascia la tua nota qui",
    "Order Time": "Tempo dell'ordine",
    "Schedule Order": "Programma ordine",
    "Minutes": "Minuti",
    "Seconds": "Secondi",
    "left.": "restante.",
    "We regret to inform you that your order has been Missed": "Ci dispiace informarti che il tuo ordine è stato perso",
    "We regret to inform you that your order has been Rejected": "Ci dispiace informarti che il tuo ordine è stato rifiutato",
    "We are pleased to inform you that your order has been Accepted": "Siamo lieti di informarti che il tuo ordine è stato accettato",
    "We are pleased to inform you that your order is Ready": "Siamo lieti di informarti che il tuo ordine è pronto",
    "Free": "Gratis",
    "By checking this box, you confirm that you are certain about proceeding with this specific order to": "Selezionando questa casella, confermi di essere sicuro di procedere con questo ordine specifico a",
    "This action is irreversible and commits to the purchase as outlined. Please review your selections carefully before confirming": "Questa azione è irreversibile e impegna all'acquisto come descritto. Rivedi attentamente le tue selezioni prima di confermare",
    "The restaurant is closed, ASAP mode is not available": "Il ristorante è chiuso, la modalità ASAP non è disponibile.",

    "Craving something delicious? Order your favorite meals from our platform and enjoy fast, easy, and reliable food delivery. Discover a wide variety of cuisines, exclusive deals, and hassle-free ordering. Satisfaction guaranteed with every bite.": "Hai voglia di qualcosa di delizioso? Ordina i tuoi piatti preferiti dalla nostra piattaforma e goditi una consegna rapida, facile e affidabile. Scopri una vasta varietà di cucine, offerte esclusive e ordini senza problemi. Soddisfazione garantita ad ogni morso.",
    "Get connected with us on social networks": "Connettiti con noi sui social network",
    "Pick your 1st choice": "Scegli la tua prima scelta",
    "Previous choices": "Scelte precedenti",
    "Now pick your": "Ora scegli il tuo",
    "choice.": "scelta.",
    "th": "°",
    "rd": "°",
    "nd": "°",
    "Chosen size": "Taglia scelta",
    "Cart will be cleared , are you sure": "Il carrello verrà svuotato, sei sicuro?",
    "From ": "da ",
    
    //new
    "Phone Number": "Numero di telefono",
    "Add Phone Number": "Aggiungi numero di telefono",
    "Enter your phone number": "Inserisci il tuo numero di telefono",
    "No payment method selected. Order creation aborted.": "Nessun metodo di pagamento selezionato. Creazione ordine annullata.",
    "Select Time Order": "Seleziona orario ordine",
    "Your order is waiting for the restaurant's approval.": "Il tuo ordine è in attesa di approvazione da parte del ristorante.",
    "Please correct the phone number.": "Correggi il numero di telefono.",
    "Unavailable": "Non disponibile",
    "Switching modes will clear your cart.": "Cambiare modalità svuoterà il tuo carrello.",
    "Would you like to proceed?": "Vuoi procedere?",
    "Enter your Delivery Address": "Inserisci il tuo indirizzo di consegna",
    "Address Type": "Tipo di indirizzo",
    "Continue": "Continua",
    "empty": "vuoto",
    "Coupon not found": "Coupon non trovato",
    "The coupon has expired and is no longer valid": "Il coupon è scaduto e non è più valido",
    "Coupon is valid and active": "Il coupon è valido e attivo",
    "Internal Server Error": "Errore interno del server",
    "Discount coupon": "Coupon sconto",
    "Apply": "Applica",
    "Enter coupon": "Inserisci coupon",
    "Have a coupon?": "Hai un coupon?",
    "The coupon has not started yet and is not valid": "Il coupon non è ancora attivo e non è valido",
}
